/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import {
	Segment,
	Divider,
	Icon,
	Container,
	Form,
	Button,
	Label,
	Header,
	Grid,
	Input,
	TextArea,
	Dropdown
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import axios from 'axios';
import { COUNTRY, PRODUCT_TYPE, STANDARD } from '../router';
import { GET_PROVINCE, GET_DISTRICT, GET_SUB_DISTRICT } from '../router';
import { connect } from 'react-redux';
import { changeFormProductImport, changeErrorsFormProductImport, reduxSource } from '../actions/actions';
import { Redirect } from 'react-router-dom';
import Headers from '../header/headerUser';
import Swal from 'sweetalert2';
import moment from 'moment'
import Modals from '../Modals';

class productImport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			indexForm: 0,
			indexWarehouse: 0,
			country: [],
			productType: [],
			productStructure: [],
			certificate: [],
			forward: false,
			backward: false,
			forward: false,
			backward: false,
			remarks: [], // เก็บข้อมูล remark ในรูปของ array
			selectedId: null, // id ที่ถูกเลือก
			remarkInput: "", // ค่า remark ที่กำลังกรอกใน modal
			isModalOpen: false, // เปิด/ปิด modal
		};
	}

	componentWillMount = () => {
		this.setState({
			optionDropdown: this.props.optionDropdown,
			form: this.props.stateForm,
			errors: this.props.stateErrors
		});
		this.GetProvince(0, 0);
		window.scrollTo(0, 0);
	};

	componentDidMount = () => {
		//Checker when refresh redirect to main page
		if (localStorage.getItem('company_id_edit') !== null && this.state.form[0].factory.nameFactory === '') {
			this.props.history.push('/entrepreneurRegister/' + localStorage.getItem('company_id_edit'))
		}

		if (this.props.forwardPage) {
			this.state.indexForm = 0;
		}
		else {
			this.state.indexForm = this.state.form.length - 1;
		}
		let country = [];
		let productType = [];
		let productStructure = [];
		let certificate = [];
		axios.get(STANDARD).then((response) => {
			response.data.value.map((data, index) => {
				let obj = {
					key: index + 1,
					text: data.name,
					value: data.name
				};
				return certificate.push(obj);
			});
			return this.setState({ certificate: certificate });
		});

		axios.get(COUNTRY).then((response) => {
			response.data.value.map((data, index) => {
				let obj = {
					key: index + 1,
					text: data.country_name,
					value: data.country_name
				};
				return country.push(obj);
			});
			return this.setState({ country: country });
		});

		axios.get(PRODUCT_TYPE).then((response) => {
			response.data.value.map((data, index) => {
				let obj = {
					key: index + 1,
					text: data.name,
					value: data.name
				};
				productType.push(obj);
				let type_struct = [];
				data.type_structs.map((data, index) => {
					let obj = {
						key: index + 1,
						text: data.name,
						value: data.name
					};
					return type_struct.push(obj);
				});
				return productStructure.push(type_struct);
			});
			this.setState({ productStructure: productStructure });
			this.setState({ productType: productType });
		});
	};

	getStandard = () => {
		let certificate = [];
		axios.get(STANDARD).then((response) => {
			response.data.value.map((data, index) => {
				let obj = {
					key: index + 1,
					text: data.name,
					value: data.name
				};
				return certificate.push(obj);
			});
			this.setState({ certificate: certificate });
		});
	};

	getCountry = () => {
		let country = [];
		axios.get(COUNTRY).then((response) => {
			response.data.value.map((data, index) => {
				let obj = {
					key: index + 1,
					text: data.country_name,
					value: data.country_name
				};
				return country.push(obj);
			});
			this.setState({ country: country });
		});
	};

	getProductype = () => {
		let productType = [];
		let productStructure = [];
		axios.get(PRODUCT_TYPE).then((response) => {
			response.data.value.map((data, index) => {
				let obj = {
					key: index + 1,
					text: data.name,
					value: data.name
				};
				productType.push(obj);
				let type_struct = [];
				data.type_structs.map((data, index) => {
					let obj = {
						key: index + 1,
						text: data.name,
						value: data.name
					};
					return type_struct.push(obj);
				});
				return productStructure.push(type_struct);
			});
			this.setState({ productStructure: productStructure });
			this.setState({ productType: productType });
		});
	};

	GetProvince = (index, indexWarehouse) => {
		axios
			.get(GET_PROVINCE)
			.then((res) => {
				const data = res.data.value;
				let dataprovince = data.map((i) => ({
					key: i.id,
					text: i.name_th,
					value: i.name_th
				}));

				this.state.optionDropdown[index].dataprovince[indexWarehouse] = dataprovince;
				this.setState({
					dataprovince: this.state.dataprovince
				});
			})
			.catch((error) => { });
	};

	GetDistrict = (value, index, indexWarehouse) => {
		try {
			axios.get(GET_DISTRICT + '?province_id=' + value).then((res) => {
				const data = res.data.value;
				let datadistrict = data.map((i) => ({
					key: i.id,
					text: i.name_th,
					value: i.name_th
				}));
				this.setState((state) => (state.optionDropdown[index].datadistrict[indexWarehouse] = datadistrict));
			});
		} catch (error) { }
	};

	GetSubDistrict = async (value, index, indexWarehouse) => {
		axios.get(GET_SUB_DISTRICT + '?amphure_id=' + value).then((res) => {
			const data = res.data.value;
			let dataSubDistrict = data.map((i) => ({
				key: i.id,
				text: i.name_th,
				value: i.name_th
			}));
			this.state.optionDropdown[index].dataSubDistrict[indexWarehouse] = dataSubDistrict;
			this.setState({
				dataSubDistrict: this.state.dataSubDistrict,
				tempSubDistrict: data
			});
		});
	};

	GetPostCode = (value, index, indexWarehouse) => {
		let zipcode = this.state.tempSubDistrict.find((data) => {
			return data.id === value;
		});
		if (zipcode !== undefined) {
			this.setState(
				(state) => (
					(state.form[index].warehouse[indexWarehouse].zip_code = zipcode.zip_code),
					(state.errors.warehouse[indexWarehouse].zip_code = false)
				)
			);
		}
		else {
			this.setState((state) => (state.form[index].warehouse.zip_code = ''));
		}
	};

	// 
	deleteFactoryImport = async () => {
		this.setState((state) => (state.form.splice(this.state.indexForm, 1), state.indexForm--));
		window.scrollTo(0, 0);
	  };

	  // ฟังก์ชันเปิด modal และตั้งค่า id ที่เลือก
	  handleOpenModal = (val) => {
		this.setState({
		  selectedId: val?.factory?.factories_id,
		  remarkInput: "", // เคลียร์ remark ก่อน
		  isModalOpen: true,
		});
	  };
	
	  // ฟังก์ชันปิด modal
	  handleCloseModal = () => {
		this.setState({
		  isModalOpen: false,
		  selectedId: null,
		});
	  };
	
	  // ฟังก์ชันจัดการการเปลี่ยนแปลงใน remark input
	  handleInputChange = (e) => {
		this.setState({ remarkInput: e.target.value });
	  };
	
	  // ฟังก์ชันยืนยัน remark และอัปเดต array
	  handleConfirmRemark = () => {
		const { selectedId, remarkInput, remarks } = this.state;
	
		if (remarkInput.trim()) {
		  const updatedRemarks = [
			...remarks,
			{ id: selectedId || "", remark: remarkInput.trim() },
		  ];

		  this.setState({
			remarks: updatedRemarks,
			isModalOpen: false, // ปิด modal หลังยืนยัน
			selectedId: null,
			remarkInput: "",
		  });
		}
		this.deleteFactoryImport()
	  };

	handleFactory = (name, value, indexForm) =>
		this.setState((state) => ((state.form[indexForm].factory[name] = value), (state.errors.factory[name] = false)));

	handleExportPerson = (name, value, index, indexForm) =>
		this.setState(
			(state) => (
				(state.form[indexForm].exportPerson[index][name] = value.toUpperCase()),
				(state.errors.exportPerson[index][name] = false)
			)
		);

	handleProductImport = (name, value, index, indexForm) =>
		this.setState(
			(state) => (
				(state.form[indexForm].productImport[index][name] = value),
				(state.errors.productImport[index][name] = false)
			)
		);

	handleChangeVendor = (name, value, index, indexForm) => {
		this.setState(
			(state) => (
				(state.form[indexForm].warehouse[index][name] = value), (state.errors.warehouse[index][name] = false)
			)
		);
	};

	handleChangeAddress = (name, value, index, indexForm) => {
		this.setState(
			(state) => (
				(state.form[indexForm].warehouse[index][name] = value), (state.errors.warehouse[index][name] = false)
			)
		);

		if (name === 'province') {
			this.state.form[indexForm].warehouse[index].district = '';
			this.state.form[indexForm].warehouse[index].sub_district = '';
			this.state.form[indexForm].warehouse[index].zip_code = '';
			this.state.optionDropdown[indexForm].datadistrict[index] = '';
			this.state.optionDropdown[indexForm].dataSubDistrict[index] = '';
			if (value !== '') {
				let valueObject = this.state.optionDropdown[indexForm].dataprovince[index].filter(
					(data) => data.value === value
				);
				this.GetDistrict(valueObject[0].key, indexForm, index);
			}
		}
		else if (name === 'district') {
			if (value !== '') {
				let valueObject = this.state.optionDropdown[indexForm].datadistrict[index].filter(
					(data) => data.value === value
				);
				this.GetSubDistrict(valueObject[0].key, indexForm, index);
			}
			this.state.form[indexForm].warehouse[index].sub_district = '';
			this.state.form[indexForm].warehouse[index].zip_code = '';
			this.state.optionDropdown[indexForm].dataSubDistrict[index] = '';
		}
		else if (name === 'sub_district') {
			if (value !== '') {
				let valueObject = this.state.optionDropdown[indexForm].dataSubDistrict[index].filter(
					(data) => data.value === value
				);
				this.GetPostCode(valueObject[0].key, indexForm, index);
			}
			this.state.form[indexForm].warehouse[index].zip_code = '';
		}
	};

	handleImportCapacity = (name, value, index, indexForm) => {
		this.setState(
			(state) => (
				(state.form[indexForm].productImport[index][name] = value
					.replace(/\D/g, '')
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',')),
				(state.errors.productImport[index][name] = false)
			)
		);
	};

	addExportPerson = (indexForm) =>
		this.setState(
			(state) => (
				(state.form[indexForm].exportPerson = [
					...state.form[indexForm].exportPerson,
					{
						export_person_id: '',
						exportPersonName: '',
						exportPersonCountry: ''
					}
				]),
				(state.errors.exportPerson = [
					...state.errors.exportPerson,
					{
						exportPersonName: false,
						exportPersonCountry: false
					}
				])
			)
		);

	productImportName = (index, indexForm) =>
		this.setState(
			(state) => (
				(state.form[indexForm].productImport[index].productName = [
					...state.form[indexForm].productImport[index].productName,
					{
						brand_id: '',
						name: ''
					}
				]),
				state.errors.productImport[index].productName.push(false)
			)
		);

	removeProductImport = (index, indexForm) =>
		this.setState((state) => state.form[indexForm].productImport.splice(index, 1));

	addProductImport = (indexForm) =>
		this.setState(
			(state) => (
				(state.form[indexForm].productImport = [
					...state.form[indexForm].productImport,
					{
						product_id: '',
						productType: 1,
						productStructure: 1,
						importCapacity: '',
						productName: [
							{
								brand_id: '',
								name: ''
							}
						]
					}
				]),
				(state.errors.productImport = [
					...state.errors.productImport,
					{
						productType: false,
						productStructure: false,
						importCapacity: false,
						productName: [false]
					}
				])
			)
		);

	addWarehouse = (indexForm) => {
		this.setState({ indexWarehouse: this.state.form[indexForm].warehouse.length }, () => {
			this.GetProvince(this.state.indexForm, this.state.indexWarehouse);
		});
		this.setState(
			(state) => (
				(state.form[indexForm].warehouse = [
					...state.form[indexForm].warehouse,
					{
						factory_import_id: '',
						address_id: '',
						alley: '',
						road: '',
						sub_district: '',
						district: '',
						province: '',
						zip_code: ''
					}
				]),
				(state.errors.warehouse = [
					...state.errors.warehouse,
					{
						address_id: false,
						alley: false,
						road: false,
						sub_district: false,
						district: false,
						province: false,
						zip_code: false
					}
				])
			)
		);
	};

	addFactory = () => {
		let index = this.state.indexForm;
		if (!this.validation()) {
			index = this.state.indexForm + 1;
			this.setState(
				(state) => (
					(state.form = [
						...this.state.form,
						{
							factory: {
								factories_id: '',
								nameFactory: '',
								certificate: 1,
								expire_certificate: '',
								from: '',
								under: '',
								location: '',
								country: '',
								file_doc: ''
							},
							productImport: [
								{
									product_id: '',
									productType: 1,
									productStructure: 1,
									importCapacity: '',
									productName: [
										{
											brand_id: '',
											name: ''
										}
									]
								}
							],
							exportPerson: [
								{
									export_person_id: '',
									exportPersonName: '',
									exportPersonCountry: ''
								}
							],
							warehouse: [
								{
									factory_import_id: '',
									address_id: '',
									alley: '',
									road: '',
									sub_district: '',
									district: '',
									province: '',
									zip_code: ''
								}
							]
						}
					]),
					((state.indexForm = state.indexForm + 1), (state.indexWarehouse = 0))
				)
			);
			this.state.optionDropdown.push({
				dataprovince: [],
				datadistrict: [],
				dataSubDistrict: []
			});

			this.getStandard();
			this.getCountry();
			this.getProductype();
			this.GetProvince(index, 0);
			this.setState({});
			this.nameFactory.focus();
		}
	};

	validation = () => {
		let form = this.state.form[this.state.indexForm];
		let error = false;
		let errors = this.state.errors;
		form.warehouse.map((warehouse, index) => {
			if (warehouse.zip_code === '') {
				errors.warehouse[index].zip_code = true;
				error = true;
				this[`warehouse[${index}].zip_code`].focus();
			}

			if (warehouse.sub_district === '') {
				errors.warehouse[index].sub_district = true;
				error = true;
				let sub_district = document.getElementById('sub_district' + index);
				sub_district.focus();
			}

			if (warehouse.district === '') {
				errors.warehouse[index].district = true;
				error = true;
				let district = document.getElementById('district' + index);
				district.focus();
			}

			if (warehouse.province === '') {
				errors.warehouse[index].province = true;
				error = true;
				let province = document.getElementById('province' + index);
				province.focus();
			}

			if (warehouse.road === '') {
				errors.warehouse[index].road = true;
				error = true;
				this[`warehouse[${index}].road`].focus();
			}

			if (warehouse.alley === '') {
				errors.warehouse[index].alley = true;
				error = true;
				this[`warehouse[${index}].alley`].focus();
			}

			if (warehouse.address_id === '') {
				errors.warehouse[index].address_id = true;
				error = true;
				this[`warehouse[${index}].address_id`].focus();
			}
			return null
		});

		for (let index = form.exportPerson.length - 1; index > -1; index--) {
			if (form.exportPerson[index].exportPersonCountry === '') {
				errors.exportPerson[index].exportPersonCountry = true;
				error = true;
				let exportPersonCountry = document.getElementById('exportPersonCountry' + index);
				exportPersonCountry.focus();
			}
			if (form.exportPerson[index].exportPersonName === '') {
				errors.exportPerson[index].exportPersonName = true;
				error = true;
				this[`exportPerson[${index}].exportPersonName`].focus();
			}
		}

		for (let index = form.productImport.length - 1; index > -1; index--) {
			for (let indexName = form.productImport[index].productName.length - 1; indexName > -1; indexName--) {
				if (form.productImport[index].productName[indexName].name === '') {
					errors.productImport[index].productName[indexName] = true;
					error = true;
					this[`productImport[${index}].productName[${indexName}]`].focus();
				}
			}
			if (form.productImport[index].importCapacity === '') {
				errors.productImport[index].importCapacity = true;
				error = true;
				this[`productImport[${index}].importCapacity`].focus();
			}
			if (form.productImport[index].productStructure === '') {
				errors.productImport[index].productStructure = true;
				error = true;
				let productStructure = document.getElementById('productStructure' + index);
				productStructure.focus();
			}
			if (form.productImport[index].productType === '') {
				errors.productImport[index].productType = true;
				error = true;
				let productType = document.getElementById('productType' + index);
				productType.focus();
			}
		}

		if (form.factory.file_doc === '') {
			errors.factory.file_doc = true;
			error = true;
		}
		if (form.factory.country === '') {
			errors.factory.country = true;
			error = true;
			let country = document.getElementById('country');
			country.focus();
		}
		if (form.factory.location === '') {
			errors.factory.location = true;
			error = true;
			this.location.focus();
		}
		if (form.factory.under === '') {
			errors.factory.under = true;
			error = true;
			this.under.focus();
		}

		if (form.factory.from === '') {
			errors.factory.from = true;
			error = true;
			this.from.focus();
		}

		if (form.factory.expire_certificate === '') {
			errors.factory.expire_certificate = true;
			error = true;
			let expire_certificate = document.getElementById('expire_certificate');
			expire_certificate.focus();
		}
		if (form.factory.certificate === '') {
			errors.factory.certificate = true;
			error = true;
			let certificate = document.getElementById('certificate');
			certificate.focus();
		}
		if (form.factory.nameFactory === '') {
			errors.factory.nameFactory = true;
			error = true;
			this.nameFactory.focus();
		}

		this.setState({ errors: errors });
		return error;
	};

	handleUploadFile = (event, indexForm) => {
		if (event.target.files[0] !== undefined) {
			if (event.target.files[0].type === 'application/pdf') {
				let { name, files } = event.target;
				if (files[0].size > 10000000) {
					Swal.fire({
						type: 'error',
						title: `ขนาดไฟล์ใหญ่เกินไป`,
						text: `ขนาดไฟล์ต้องไม่เกิน 10 MB`
					});
					return;
				}
				this.setState(
					(state) => (
						(state.form[indexForm].factory[name] = files[0]), (state.errors.factory.file_doc = false)
					)
				);
			}
			else {
				Swal.fire({
					type: 'error',
					title: `ชนิดไฟล์ไม่ถูกต้อง 
				กรุณาตรวจสอบใหม่อีกครั้ง`
				});
			}
		}
	};

	handleUploadFileReset = (event, indexForm) => {
		this.setState((state) => (state.form[indexForm].factory.file_doc = ''));
	};

	checkValidateToForward = () => {
		if (!this.validation()) {
			this.props.dispatch(changeFormProductImport(this.state.form));
			this.props.dispatch(reduxSource({
				...this.props.stateRedux,
				remarks_import: this.state.remarks, // เก็บข้อมูล remark ในรูปของ array
				selectedId_import: this.state.selectedId, // id ที่ถูกเลือก
				remarkInput_import: this.state.remarkInput, // ค่า remark ที่กำลังกรอกใน modal
				isModalOpen_import: this.state.isModalOpen, // เปิด/ปิด modal
			}))
			this.props.dispatch(changeErrorsFormProductImport(this.state.errors));
			this.setState({ forward: true });
		}
	};
	checkValidateToBackward = () => {
		this.props.dispatch(changeFormProductImport(this.state.form));
		this.props.dispatch(changeErrorsFormProductImport(this.state.errors));
		this.setState({ backward: true });
	};

	render() {
		var CurrentDate = moment();
		let indexForm = this.state.indexForm;
		const factory = this.state.form[indexForm].factory;
		const productImports = this.state.form[indexForm].productImport;
		const exportPerson = this.state.form[indexForm].exportPerson;
		const warehouses = this.state.form[indexForm].warehouse;
		const error = this.state.errors;
		if (this.state.forward) return <Redirect to='/addAuthorized' />;
		if (this.state.backward) {
			if (this.props.previous) {
				return <Redirect to='/productRegister' />;
			}
			else {
				return <Redirect to='/entrepreneurRegister' />;
			}
		}

		return (
			<div className='App'>
				<Headers />
				<Modals.ConfirmRemark open={this.state.isModalOpen} onClose={this.handleCloseModal} value={this.state.remarkInput}
                onChange={this.handleInputChange}  callback={this.handleConfirmRemark} />
				<Container>
					<br />
					<Form>
						<Segment padded='very' color='violet'>
							<Header>
								<h1>
									<Icon color='violet' name='warehouse' />ข้อมูลการนำเข้า
								</h1>
							</Header>
							<Divider />
							{/* <Segment padded='very' color='violet'> */}
							<h3>ข้อมูลโรงงานที่ {indexForm + 1}</h3>
							<Grid>
								<Grid.Row>
									<Grid.Column>
										<Form.Field error={error.factory.nameFactory}>
											<label>
												ชื่อโรงงาน <span style={{ color: 'red' }}>*</span>
											</label>
											<Input
												name='nameFactory'
												onChange={(e, { name, value }) =>
													this.handleFactory(name, value.toUpperCase(), indexForm)}
												value={factory.nameFactory}
												ref={(input) => (this.nameFactory = input)}
											/>
											<p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
												หมายเหตุ : กรอกชื่อโรงงานให้ตรงตามใบรับรองหรือใบอนุญาต
                    							</p>
										</Form.Field>
										{/* </Grid.Column> */}
										{/* <Grid.Column computer={15}> */}
										<Form.Field error={error.factory.certificate}>
											<label>
												ได้รับใบรับรองระบบบริหารงานคุณภาพ <span style={{ color: 'red' }}>*</span>
											</label>
											<Dropdown
												search
												fluid
												selection
												wrapSelection={false}
												options={this.state.certificate}
												placeholder='Choose an option'
												value={factory.certificate}
												name='certificate'
												id='certificate'
												onChange={(e, { name, value }) =>
													this.handleFactory(name, value, indexForm)}
											/>
										</Form.Field>
										{/* </Grid.Column>
										<Grid.Column computer={15}> */}

										{/* </Grid.Column>
										<Grid.Column computer='15'> */}
										<Form.Group widths='equal'>
											<Form.Field error={error.factory.from}>
												<label>
													จาก
														<span style={{ color: 'red' }}>*</span>
												</label>
												<Input
													name='from'
													onChange={(e, { name, value }) =>
														this.handleFactory(name, value, indexForm)}
													value={factory.from}
													ref={(input) => (this.from = input)}
												/>
												<p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem', fontSize: '11.5px' }}>
													หมายเหตุ : กรอกชื่อหน่วยงานผู้ให้การรับรอง (Certification Body: CB)
                    								</p>
											</Form.Field>
											<Form.Field error={error.factory.under}>
												<label>
													ภายใต้
														<span style={{ color: 'red' }}>*</span>
												</label>
												<Input
													name='under'
													onChange={(e, { name, value }) =>
														this.handleFactory(name, value, indexForm)}
													value={factory.under}
													ref={(input) => (this.under = input)}
												/>
												<p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem', fontSize: '11.5px' }}>
													หมายเหตุ : กรอกชื่อผู้ตรวจประเมิณและให้การรับรอง CB (Accreditation Body: AB)
                    								</p>
											</Form.Field>
										</Form.Group>
										<Form.Field error={error.factory.expire_certificate}>
											<label>
												วันหมดอายุใบรับรองระบบบริหารงานคุณภาพ{' '}
												<span style={{ color: 'red' }}>*</span>
											</label>
											<DateInput
												// minDate={CurrentDate}
												fluid
												placeholder='เลือกวันหมดอายุใบรับรองระบบบริหารงานคุณภาพ'
												iconPosition='left'
												startMode='year'
												dateFormat='DD/MM/YYYY'
												popupPosition='bottom center'
												animation='glow'
												duration={100}
												closable={true}
												clearable={true}
												markColor='violet'
												hideMobileKeyboard={true}
												inline={false}
												value={factory.expire_certificate}
												name='expire_certificate'
												id='expire_certificate'
												onChange={(e, { name, value }) => {
													var dateValid = moment(value, "DD/MM/YYYY", true).isValid()
													if (dateValid) {
														this.setState((state) => (state.form[indexForm].factory[`${name}_TEMP`] = value))
													}

													this.handleFactory(name, value, indexForm);
												}}

												onBlur={() => {
													const state = this.state.form[indexForm].factory
													if (!moment(state.expire_certificate).isValid() && state.expire_certificate !== '') {
														const value = state.expire_certificate_TEMP ? state.expire_certificate_TEMP : ''
														this.handleFactory('expire_certificate',value  , indexForm);

													}

												}}

											/>
										</Form.Field>
										{/* </Grid.Column>
										<Grid.Column computer='15'> */}
										<Form.Field error={error.factory.location}>
											<label>
												ที่ตั้งโรงงาน
													<span style={{ color: 'red' }}>*</span>
											</label>
											<TextArea
												label='ที่ตั้งโรงงาน'
												name='location'
												onChange={(e, { name, value }) =>
													this.handleFactory(name, value, indexForm)}
												value={factory.location}
												ref={(input) => (this.location = input)}
											/>
											<p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
												หมายเหตุ : กรอกที่ตั้งโรงงานให้ตรงตามใบรับรองหรือใบอนุญาต
                    							</p>
										</Form.Field>
										{/* </Grid.Column>
										<Grid.Column computer='15'> */}
										<Form.Field error={error.factory.country}>
											<label>
												ประเทศ
													<span style={{ color: 'red' }}>*</span>
											</label>
											<Dropdown
												search
												selection
												wrapSelection={false}
												options={this.state.country}
												placeholder='Choose an option'
												value={factory.country}
												id='country'
												name='country'
												onChange={(e, { name, value }) =>
													this.handleFactory(name, value, indexForm)}
											/>
										</Form.Field>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row style={{ display: 'flex', justifyContent: 'center' }}>
									<Grid.Column computer={4} tablet={4}>
										<label>
											<b>เอกสารแนบ: ใบรับรองระบบบริหารงานคุณภาพ</b>
											<span style={{ color: 'red' }}>*</span>
										</label>
									</Grid.Column>
									<Grid.Column computer={8} tablet={8}>
										<Segment>
											<Label
												attached='top'
												color={error.factory.file_doc ? 'red' : 'violet'}
												as='label'
												htmlFor='file_doc'
												size='large'
											>
												<Icon name='upload' />
													เลือกไฟล์
													<input
													hidden
													type='file'
													id='file_doc'
													name='file_doc'
													accept='.pdf'
													onChange={(e) => this.handleUploadFile(e, indexForm)}
													onClick={(e) => { e.target.value = null }}
												/>
											</Label>
											<p>
												{factory.file_doc.name !== undefined && factory.file_doc.name}
													&nbsp; &nbsp;
													{factory.file_doc.name && (
													<Icon
														id='file_doc_reset'
														color='red'
														size='large'
														name='times circle outline'
														link
														onClick={(e) => this.handleUploadFileReset(e, indexForm)}
													/>
												)}
											</p>
										</Segment>
										<p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
											ชนิดไฟล์ที่ใช้อัปโหลด : .pdf ขนาดไฟล์ไม่เกิน 10 M
											</p>
									</Grid.Column>
									<Grid.Column computer={4} tablet={4}>
										<Label
											size='large'
											basic
											color='red'
											pointing='left'
											style={{
												display: error.factory.file_doc ? 'block' : 'none'
											}}
										>
											<Icon name='warning circle' />
												กรุณาเพิ่มไฟล์ !
											</Label>
									</Grid.Column>
								</Grid.Row>
							</Grid>
							{/* </Segment> */}
							<Segment padded='very' color='violet'>
								<h3>ข้อมูลผลิตภัณฑ์นำเข้า</h3>
								{productImports.map((productImport, index) => (
									<Segment padded='very' color='violet' key={index}>
										<Grid>
											<Grid.Row>
												<Grid.Column computer='10' tablet='16'>
													<Form.Group widths='equal'>
														<Form.Field error={error.productImport[index].productType}>
															<label>ผลิตภัณฑ์ที่ {index + 1}</label>
															<Dropdown
																selection
																wrapSelection={false}
																options={this.state.productType}
																value={productImport.productType}
																placeholder='Choose an option'
																name='productType'
																id={'productType' + index}
																onChange={(e, { name, value }) => {
																	this.handleProductImport(
																		name,
																		value,
																		index,
																		indexForm
																	);
																}}
																ref={(input) => {
																	this[`productImport[${index}].productType`] = input;
																}}
															/>
														</Form.Field>
														<Form.Field error={error.productImport[index].productStructure}>
															<label>โครงสร้าง</label>
															<Dropdown
																selection
																wrapSelection={false}
																options={
																	this.state.productStructure[
																	this.state.productType
																		.map((data) => data.value)
																		.indexOf(productImport.productType)
																	]
																}
																id={'productStructure' + index}
																value={productImport.productStructure}
																placeholder='Choose an option'
																name='productStructure'
																onChange={(e, { name, value }) => {
																	this.handleProductImport(
																		name,
																		value,
																		index,
																		indexForm
																	);
																}}
																ref={(input) => {
																	this[
																		`productImport[${index}].productStructure`
																	] = input;
																}}
															/>
														</Form.Field>
													</Form.Group>
												</Grid.Column>
												<Grid.Column computer='5' tablet='16'>
													<Form.Field error={error.productImport[index].importCapacity}>
														<label>ปริมาณการนำเข้า (เส้น/ปี)</label>
														<Input
															name='importCapacity'
															onChange={(e, { name, value }) => {
																this.handleImportCapacity(
																	name,
																	value,
																	index,
																	indexForm
																);
															}}
															value={productImport.importCapacity}
															ref={(input) => {
																this[`productImport[${index}].importCapacity`] = input;
															}}
														/>
														<p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
															หมายเหตุ : กรอกเฉพาะตัวเลข
                    									</p>
													</Form.Field>
												</Grid.Column>
												{productImport.productName.map((data, indexName) => {
													return (
														<Grid.Column computer='5' tablet='8' key={indexName}>
															<Form.Field
																error={
																	error.productImport[index].productName[indexName]
																}
															>
																<label>ชื่อทางการค้าที่ {indexName + 1}</label>
																{productImport.productName.length > 1 ? (
																	<Input
																		action={{
																			icon: 'delete',
																			onClick: () => {
																				productImport.productName.splice(
																					indexName,
																					1
																				);
																				this.setState({});
																			}
																		}}
																		value={data.name}
																		name='productName'
																		onChange={(e, { value }) => {
																			productImport.productName[
																				indexName
																			].name = value.toUpperCase();
																			error.productImport[index].productName[
																				indexName
																			] = false;
																			this.setState({});
																		}}
																		ref={(input) => {
																			this[
																				`productImport[${index}].productName[${indexName}]`
																			] = input;
																		}}
																	/>
																) : (
																		<Input
																			value={data.name}
																			name='productName'
																			onChange={(e, { value }) => {
																				productImport.productName[
																					indexName
																				].name = value.toUpperCase();
																				error.productImport[index].productName[
																					indexName
																				] = false;
																				this.setState({});
																			}}
																			ref={(input) => {
																				this[
																					`productImport[${index}].productName[${indexName}]`
																				] = input;
																			}}
																		/>
																	)}
															</Form.Field>
														</Grid.Column>
													);
												})}
											</Grid.Row>
											<Grid.Row>
												<Grid.Column computer='13' tablet='11'>
													<Form.Button
														color='teal'
														icon
														labelPosition='left'
														onClick={() => this.productImportName(index, indexForm)}
													>
														เพิ่มชื่อทางการค้า
														<Icon name='plus' />
													</Form.Button>
												</Grid.Column>
												{productImports.length > 1 && (
													<Grid.Column computer='3' tablet='5'>
														<Form.Button
															floated='right'
															color='red'
															onClick={() => this.removeProductImport(index, indexForm)}
														>
															ลบข้อมูล
														</Form.Button>
													</Grid.Column>
												)}
											</Grid.Row>
										</Grid>
									</Segment>
								))}
								<br />
								<Button
									color='teal'
									floated='right'
									icon
									labelPosition='left'
									onClick={() => this.addProductImport(indexForm)}
								>
									เพิ่มผลิตภัณฑ์
									<Icon name='plus' />
								</Button>
								<br />
							</Segment>

							<Segment padded='very' color='violet'>
								<h3>ข้อมูลผู้ส่งออก</h3>
								<Grid style={{ marginLeft: 50 }}>
									{exportPerson.map((data, index) => (
										<Grid.Row key={index}>
											<Grid.Column computer='6' tablet='16'>
												<Form.Field error={error.exportPerson[index].exportPersonName}>
													<label>ชื่อผู้ส่งออก</label>
													<Input
														name='exportPersonName'
														value={data.exportPersonName}
														onChange={(e, { name, value }) =>
															this.handleExportPerson(name, value, index, indexForm)}
														ref={(input) => {
															this[`exportPerson[${index}].exportPersonName`] = input;
														}}
													/>
													<p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
														หมายเหตุ : กรอกชื่อบริษัทผู้ส่งออกสินค้า
													</p>
												</Form.Field>
											</Grid.Column>
											<Grid.Column computer='6' tablet='16'>
												<Form.Field error={error.exportPerson[index].exportPersonCountry}>
													<label>ประเทศ</label>
													<Form.Dropdown
														selection
														search
														wrapSelection={false}
														options={this.state.country}
														value={data.exportPersonCountry}
														placeholder='Choose an option'
														id={'exportPersonCountry' + index}
														name='exportPersonCountry'
														onChange={(e, { name, value }) =>
															this.handleExportPerson(name, value, index, indexForm)}
													/>
												</Form.Field>
											</Grid.Column>
											{exportPerson.length > 1 && (
												<Grid.Column computer='3' tablet='5'>
													<Button
														style={{
															marginTop: 25
														}}
														color='red'
														onClick={() => {
															exportPerson.splice(index, 1);
															this.setState({});
														}}
													>
														ลบข้อมูล
													</Button>
												</Grid.Column>
											)}
										</Grid.Row>
									))}

									<Form.Button
										icon
										color='teal'
										labelPosition='left'
										onClick={() => this.addExportPerson(indexForm)}
									>
										เพิ่มผู้ส่งออก
										<Icon name='plus' />
									</Form.Button>
								</Grid>
							</Segment>

							<Segment padded='very' color='violet'>
								<h3>ข้อมูลสถานที่เก็บสินค้าในประเทศไทย</h3>
								<br />
								{warehouses.map((warehouse, index) => (
									<Segment padded='very' color='violet'>
										<h4>สถานที่เก็บสินค้าลำดับที่ {index + 1} </h4>
										<Form.Field error={error.warehouse[index].address_id}>
											<label>
												เลขที่<span style={{ color: 'red' }}>*</span>
											</label>
											<Input
												size='large'
												icon='point'
												iconPosition='left'
												name='address_id'
												placeholder='ตัวอย่าง: XX/XX หมู่ที่ XX'
												value={warehouse.address_id}
												onChange={(e, { name, value }) => {
													this.handleChangeVendor(name, value, index, indexForm);
												}}
												ref={(input) => {
													this[`warehouse[${index}].address_id`] = input;
												}}
											/>
											<p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
												หมายเหตุ : กรอกเลขที่และหมู่ที่ (หากมี)
											</p>
										</Form.Field>
										<Form.Field error={error.warehouse[index].alley}>
											<label>
												ซอย<span style={{ color: 'red' }}>*</span>
											</label>
											<Input
												size='large'
												icon='compress'
												iconPosition='left'
												name='alley'
												value={warehouse.alley}
												onChange={(e, { name, value }) => {
													this.handleChangeVendor(name, value, index, indexForm);
												}}
												ref={(input) => {
													this[`warehouse[${index}].alley`] = input;
												}}
											/>
										</Form.Field>
										<Form.Field error={error.warehouse[index].road}>
											<label>
												ถนน<span style={{ color: 'red' }}>*</span>
											</label>
											<Input
												size='large'
												icon='road'
												iconPosition='left'
												name='road'
												value={warehouse.road}
												onChange={(e, { name, value }) => {
													this.handleChangeVendor(name, value, index, indexForm);
												}}
												ref={(input) => {
													this[`warehouse[${index}].road`] = input;
												}}
											/>
										</Form.Field>
										<Form.Field error={error.warehouse[index].province}>
											<label>
												จังหวัด<span style={{ color: 'red' }}>*</span>
											</label>
											<Dropdown
												search
												fluid
												placeholder='เลือกจังหวัด'
												clearable
												selection
												id={'province' + index}
												name='province'
												options={this.state.optionDropdown[indexForm].dataprovince[index]}
												value={warehouse.province}
												onChange={(e, { name, value }) =>
													this.handleChangeAddress(name, value, index, indexForm)}
											/>
										</Form.Field>
										<Form.Field error={error.warehouse[index].district}>
											<label>
												อำเภอ/เขต<span style={{ color: 'red' }}>*</span>
											</label>
											<Dropdown
												search
												fluid
												placeholder='เลือกอำเภอ/เขต'
												clearable
												selection
												id={'district' + index}
												name='district'
												options={this.state.optionDropdown[indexForm].datadistrict[index]}
												value={warehouse.district}
												disabled={warehouse.province === '' ? true : false}
												onChange={(e, { name, value }) =>
													this.handleChangeAddress(name, value, index, indexForm)}
											/>
										</Form.Field>
										<Form.Field error={error.warehouse[index].sub_district}>
											<label>
												ตำบล/แขวง<span style={{ color: 'red' }}>*</span>
											</label>
											<Dropdown
												search
												fluid
												placeholder='เลือกตำบล/แขวง'
												clearable
												selection
												id={'sub_district' + index}
												name='sub_district'
												options={this.state.optionDropdown[indexForm].dataSubDistrict[index]}
												value={warehouse.sub_district}
												disabled={warehouse.district === '' ? true : false}
												onChange={(e, { name, value }) =>
													this.handleChangeAddress(name, value, index, indexForm)}
											/>
										</Form.Field>
										<Form.Field error={error.warehouse[index].zip_code}>
											<label>
												รหัสไปรษณีย์<span style={{ color: 'red' }}>*</span>
											</label>
											<Input
												icon='envelope'
												iconPosition='left'
												maxLength='5'
												placeholder='รหัสไปรษณีย์'
												name='zip_code'
												value={warehouse.zip_code}
												onChange={(e, { name, value }) => {
													this.handleChangeVendor(name, value, index, indexForm);
												}}
												disabled={warehouse.sub_district === '' ? true : false}
												ref={(input) => {
													this[`warehouse[${index}].zip_code`] = input;
												}}
											/>
										</Form.Field>
										{warehouses.length > 1 && (
											<Grid.Column
												computer='3'
												tablet='5'
												style={{ paddingTop: 20, paddingBottom: 20 }}
											>
												<Button
													color='red'
													floated='right'
													onClick={() => {
														warehouses.splice(index, 1);
														this.setState({});
													}}
												>
													ลบข้อมูล
												</Button>
											</Grid.Column>
										)}
									</Segment>
								))}
							</Segment>
							<br />
							<Form.Button
								icon
								color='teal'
								labelPosition='left'
								floated='right'
								onClick={() => this.addWarehouse(indexForm)}
							>
								เพิ่มสถานที่เก็บสินค้า
								<Icon name='plus' />
							</Form.Button>
							<br />
						</Segment>
					</Form>
					<br />
					{indexForm > 0 ? (
						<Button
							icon
							labelPosition='left'
							floated='left'
							onClick={() => {
								this.setState({ indexForm: indexForm - 1 });
								window.scrollTo(0, 0);
							}}
						>
							กลับไปหน้าที่แล้ว
							<Icon name='left arrow' />
						</Button>
					) : (
							<Button icon labelPosition='left' floated='left' onClick={this.checkValidateToBackward}>
								กลับไปหน้าที่แล้ว
								<Icon name='left arrow' />
							</Button>
						)}

					{this.state.form.length - 1 === indexForm ? (
						<div>
							<Button
								icon
								labelPosition='left'
								color='violet'
								floated='right'
								onClick={this.checkValidateToForward}
							>
								ไปหน้าถัดไป
								<Icon name='right arrow' />
							</Button>
							<Button
								style={{ marginRight: 20 }}
								icon
								labelPosition='left'
								type='button'
								primary
								floated='right'
								onClick={this.addFactory}
							>
								เพิ่มโรงงาน (นำเข้า)
								<Icon name='warehouse' />
							</Button>
						</div>
					) : (
							<div>
								<Button
									icon
									labelPosition='left'
									color='violet'
									floated='right'
									onClick={() => {
										if (!this.validation()) {
											if (this.state.form[indexForm].warehouse !== undefined) {
												this.GetProvince(indexForm + 1, 0);
											}
											this.setState({ indexForm: indexForm + 1 });
											window.scrollTo(0, 0);
										}
									}}
								>
									ไปหน้าถัดไป
								<Icon name='right arrow' />
								</Button>
							</div>
						)}
					{indexForm !== 0 && (
						<Button
							style={{ marginRight: 20 }}
							icon
							labelPosition='left'
							type='button'
							color='red'
							floated='right'
							onClick={()=>this.handleOpenModal(this.state.form[indexForm])}
							// onClick={() => {
								// this.setState((state) => (state.form.splice(indexForm, 1), state.indexForm--));
								// window.scrollTo(0, 0);
							// }}
						>
							ลบโรงงาน (นำเข้า)
							<Icon name='warehouse' />
						</Button>
					)}

					<br />
					<br />
					<br />
					<br />
				</Container>
			</div>
		);
	}
}
const mapStateToProps = function (state) {
	return {
		message: 'This is message from mapStateToProps',
		stateForm: state.reduxxx.formProductImport,
		stateErrors: state.reduxxx.errorsFormProductImport,
		previous: state.reduxxx.productRegister,
		optionDropdown: state.reduxxx.optionAddressProductImport,
		forwardPage: state.reduxxx.forwardProductImport,
		stateRedux: state.reduxxx,
	};
};
export default connect(mapStateToProps)(productImport);

import { useEffect } from 'react'

import 'vanilla-cookieconsent/dist/cookieconsent.js'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import './cookieconsent.css'

const config = {
    current_lang: "th",
    autorun: true,
    autoclear_cookies: true,
    page_scripts: true,
    cookie_name: 'eco_tyre_cookie_consent',
    gui_options: {
        consent_modal: {
            layout: 'cloud',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'bar',                 // box/bar
            position: 'right',           // left/right
            transition: 'slide'            // zoom/slide
        }
    },
    languages: {
        th: {
            consent_modal: {
                title: "เว็บไซต์นี้ใช้คุกกี้",
                description:
                    `ระบบป้ายข้อมูลตามมาตรฐานสากล (Eco Sticker ยางรถยนต์) มีการใช้งานคุกกี้ (Cookies) 
                    เพื่อจัดการข้อมูลส่วนบุคคลและช่วยเพิ่มประสิทธิภาพการใช้งานเว็บไซต์
                    คุณสามารถเลือกตั้งค่าความยินยอมการใช้คุกกี้ได้ โดยคลิก “การตั้งค่าคุกกี้”`,
                primary_btn: {
                    text: "ยอมรับทั้งหมด",
                    role: "accept_all", // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: "การตั้งค่าคุกกี้",
                    role: "c-settings", // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: "นโยบายคุกกี้ (Cookies Policy)",
                save_settings_btn: "ยืนยันตัวเลือก",
                accept_all_btn: "ยอมรับทั้งหมด",
                close_btn_label: "Close",
                cookie_table_headers: [ // สำหรับแสดงผลตาราง cookie_table ใน analytic
                    { col1: "Name" },
                    { col2: "Domain" },
                    { col3: "Expiration" },
                    { col4: "Description" },
                ],
                blocks: [
                    {
                        title: "นโยบายคุกกี้",
                        description:
                            `สำนักงานเศรษฐกิจอุตสาหกรรม (สศอ.) สังกัด กระทรวงอุตสาหกรรม 
                            ในฐานะผู้พัฒนาและบริหารจัดการเว็บไซต์ (“*.tyre.go.th”)   
                            เมื่อท่านได้เข้าสู่เว็บไซต์ (“*.tyre.go.th”) ข้อมูลที่เกี่ยวข้องกับการเข้าสู่เว็บไซต์ของท่าน
                            จะถูกบันทึกไว้ในรูปแบบของคุกกี้ โดยนโยบายคุกกี้นี้จะอธิบายถึงความหมาย การทำงาน วัตถุประสงค์ 
                            รวมถึงการลบและการปฏิเสธการเก็บคุกกี้ เพื่อความเป็นส่วนตัวของท่าน 
                            โดยการเข้าสู่เว็บไซต์นี้ถือว่าท่านได้อนุญาตให้เราใช้คุกกี้ตามนโยบายคุกกี้ที่มีรายละเอียดดังต่อไปนี้`,
                    },
                    {
                        title: "คุกกี้คืออะไร",
                        description:
                            `คุกกี้ คือ ไฟล์เล็ก ๆ เพื่อจัดเก็บข้อมูลการเข้าใช้งานเว็บไซต์ เช่น วันเวลา ลิงค์ที่คลิก หน้าที่เข้าชม
                            เงื่อนไขการตั้งค่าต่าง ๆ โดยจะบันทึกลงไปในอุปกรณ์คอมพิวเตอร์ และ/หรือ 
                            เครื่องมือสื่อสารที่เข้าใช้งานของท่าน เช่น โน๊ตบุ๊ค แท็บเล็ต หรือ สมาร์ทโฟน 
                            ผ่านทางเว็บเบราว์เซอร์ในขณะที่ท่านเข้าสู่เว็บไซต์ โดยคุกกี้จะไม่ก่อให้เกิดอันตรายต่ออุปกรณ์คอมพิวเตอร์ 
                            และ/หรือ เครื่องมือสื่อสารของท่าน ในกรณีดังต่อไปนี้ ข้อมูลส่วนบุคคลของท่านอาจถูกจัดเก็บเพื่อใช้เพิ่ม
                            ประสบการณ์การใช้งานบริการทางออนไลน์ โดยจะจำเอกลักษณ์ของภาษาและปรับแต่งข้อมูลการใช้งาน
                            ตามความต้องการของท่าน เป็นการยืนยันคุณลักษณะเฉพาะตัว ข้อมูลความปลอดภัยของท่าน รวมถึงบริการที่ท่านสนใจ 
                            นอกจากนี้คุกกี้ยังถูกใช้เพื่อวัดปริมาณการเข้าใช้งานบริการทางออนไลน์ การปรับเปลี่ยนเนื้อหาตามการใช้งานของท่านโดยพิจารณา
                            จากพฤติกรรมการเข้าใช้งานครั้งก่อน ๆ และ ณ ปัจจุบัน และอาจมีวัตถุประสงค์เพื่อการโฆษณาประชาสัมพันธ์ 
                            ทั้งนี้ท่านสามารถค้นหาข้อมูลเพิ่มเติมเกี่ยวกับคุกกี้ได้ที่ www.allaboutcookies.org`,
                    },{
                        title: "สศอ. ใช้คุกกี้อย่างไร",
                        description:
                            `สศอ. ใช้คุกกี้ เพื่อบันทึกการเข้าเยี่ยมชมและสมัครเข้าใช้งานเว็บไซต์ของท่าน โดยทำให้ สศอ. 
                            สามารถจดจำการใช้งานเว็บไซต์ของท่านได้ง่ายขึ้น และข้อมูลเหล่านี้จะถูกนำไปเพื่อปรับปรุงเว็บไซต์ของ สศอ.
                            ให้ตรงกับความต้องการของท่านมากยิ่งขึ้น เพื่ออำนวยความสะดวกให้เกิดความรวดเร็วในการใช้งานเว็บไซต์ของท่าน
                            และในบางกรณี สศอ. จำเป็นต้องให้บุคคลที่สามช่วยดำเนินการดังกล่าว ซึ่งอาจจะต้องใช้ 
                            อินเตอร์เน็ตโปรโตคอลแอดเดรส (IP Address) และคุกกี้เพื่อวิเคราะห์ทางสถิติ ตลอดจนเชื่อมโยงข้อมูล
                            และประมวลผลตามวัตถุประสงค์ของหน่วยงาน
                            `,
                    },{
                        title: "Persistent Cookie ",
                        description:
                            `Persistent Cookie เป็นคุกกี้ที่จะอยู่ตามระยะเวลาที่กำหนดหรือจนกว่าท่านจะลบออก 
                            คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ของ สศอ. จดจำท่านและการตั้งค่าต่าง ๆ 
                            ของท่านเมื่อท่านกลับมาใช้บริการเว็บไซต์อีกครั้ง 
                            ซึ่งจะช่วยให้ท่านเข้าใช้บริการเว็บไซต์ได้สะดวกรวดเร็วยิ่งขึ้น`,
                        toggle: {
                            value: "persistent",
                            enabled: true,
                            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                    },
                    {
                        title: "Session Cookies ",
                        description:
                            `Session Cookies เป็นคุกกี้ที่จะอยู่ชั่วคราวเพื่อจดจำท่านในระหว่างที่ท่านเข้าเยี่ยมชมเว็บไซต์ของ สศอ. 
                            เช่น เฝ้าติดตามภาษาที่ท่านได้ตั้งค่าและเลือกใช้ เป็นต้น และจะมีการลบออกจากเครื่องคอมพิวเตอร์หรืออุปกรณ์ของท่าน 
                            เมื่อท่านออกจากเว็บไซต์หรือได้ทำการปิดเว็บเบราว์เซอร์`,
                        toggle: {
                            value: "session",
                            enabled: true,
                            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                    },
                    {
                        title: "Performance and Analytics cookies",
                        description:
                            `คุกกี้ในส่วนวิเคราะห์ จะช่วยให้เว็บไซต์เข้าใจรูปแบบการใช้งานของผู้เข้าชมและจะช่วยปรับปรุงประสบการณ์การใช้งาน โดยการเก็บรวบรวมข้อมูลและรายงานผลการใช้งานของผู้ใช้งาน`,
                        toggle: {
                            value: "analytics", // your cookie category
                            enabled: true,
                            readonly: false,
                        },
                        cookie_table: [
                            // list of all expected cookies
                            {
                                col1: "^_ga", // match all cookies starting with "_ga"
                                col2: "google.com",
                                col3: "2 years",
                                col4: "-",
                                is_regex: true,
                            },
                            {
                                col1: "_gid",
                                col2: "google.com",
                                col3: "1 day",
                                col4: "-",
                                is_regex: true,
                            },
                        ],
                    },
                    {
                        title: "วัตถุประสงค์ในการใช้งานคุกกี้ที่ สศอ. ใช้ มีรายละเอียดดังนี้",
                        description:
                            `
                                <p class="bold-text">คุกกี้ที่มีความจำเป็น (Strictly Necessary Cookies) </p>
                                คุกกี้ประเภทนี้มีความจำเป็นต่อการให้บริการเว็บไซต์ของ สศอ. เพื่อให้ท่านสามารถเข้าใช้งานในส่วนต่าง ๆ 
                                ของเว็บไซต์ได้ รวมถึงช่วยจดจำข้อมูลที่ท่านเคยให้ไว้ผ่านเว็บไซต์ การปิดการใช้งานคุกกี้ประเภทนี้จะส่งผลให้ท่าน
                                ไม่สามารถใช้บริการในสาระสำคัญของ สศอ. ซึ่งจำเป็นต้องเรียกใช้คุกกี้ได้</br>

                                <p class="bold-text">คุกกี้เพื่อการวิเคราะห์และประเมินผลการใช้งาน (Performance Cookies)</p>
                                คุกกี้ประเภทนี้ช่วยให้ สศอ. ทราบถึงการปฏิสัมพันธ์ของผู้ใช้งานในการใช้บริการเว็บไซต์ของ สศอ.รวมถึงหน้า
                                เพจหรือพื้นที่ใดของเว็บไซต์ที่ได้รับความนิยม ตลอดจนการวิเคราะห์ข้อมูลด้านอื่น ๆ สศอ. 
                                ยังใช้ข้อมูลนี้เพื่อการปรับปรุงการทำงานของเว็บไซต์ และเพื่อเข้าใจพฤติกรรมของผู้ใช้งานมากขึ้น
                                 ถึงแม้ว่า ข้อมูลที่คุกกี้นี้เก็บรวบรวมจะเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ และนำมาใช้วิเคราะห์ทางสถิติเท่านั้น 
                                 การปิดการใช้งานคุกกี้ประเภทนี้จะส่งผลให้ สศอ. ไม่สามารถทราบปริมาณผู้เข้าเยี่ยมชมเว็บไซต์ 
                                 และไม่สามารถประเมินคุณภาพการให้บริการได้</br>

                                <p class="bold-text">คุกกี้เพื่อการใช้งานเว็บไซต์ (Functional Cookies)</p>
                                คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ของ สศอ. จดจำตัวเลือกต่าง ๆ ที่ท่านได้ตั้งค่าไว้และช่วยให้เว็บไซต์ส่งมอบคุณสมบัติและ
                                เนื้อหาเพิ่มเติมให้ตรงกับการใช้งานของท่านได้ เช่น ช่วยจดจำชื่อบัญชีผู้ใช้งานของท่าน หรือจดจำการเปลี่ยนแปลง
                                การตั้งค่าขนาดฟอนต์หรือการตั้งค่าต่าง ๆ ของหน้าเพจซึ่งท่านสามารถปรับแต่งได้ การปิดการใช้งานคุกกี้
                                ประเภทนี้อาจส่งผลให้เว็บไซต์ไม่สามารถทำงานได้อย่างสมบูรณ์</br>

                                <p class="bold-text">คุกกี้เพื่อการโฆษณาไปยังกลุ่มเป้าหมาย (Targeting Cookies)</p>
                                คุกกี้ประเภทนี้เป็นคุกกี้ที่เกิดจากการเชื่อมโยงเว็บไซต์ของบุคคลที่สาม ซึ่งเก็บข้อมูลการเข้าใช้งานและเว็บไซต์
                                ที่ท่านได้เข้าเยี่ยมชม เพื่อนำเสนอสินค้าหรือบริการบนเว็บไซต์อื่นที่ไม่ใช่เว็บไซต์ของ สศอ. 
                                ทั้งนี้ หากท่านปิดการใช้งานคุกกี้ประเภทนี้จะไม่ส่งผลต่อการใช้งานเว็บไซต์ของ สศอ. 
                                แต่จะส่งผลให้การนำเสนอสินค้าหรือบริการบนเว็บไซต์อื่น ๆ ไม่สอดคล้องกับความสนใจของท่าน
                            `,  
                    }, {
                        title: "ท่านจะจัดการคุกกี้ได้อย่างไร",
                        description:
                            `บราวเซอร์ส่วนใหญ่จะมีการตั้งค่าให้มีการยอมรับคุกกี้เป็นค่าเริ่มต้น อย่างไรก็ตาม ท่านสามารถปฏิเสธการใช้งานหรือลบคุกกี้ใน
                            หน้าการตั้งค่าของบราวเซอร์ที่ท่านใช้งานอยู่ ทั้งนี้ หากท่านทำการปรับเปลี่ยนการตั้งค่าบราวเซอร์ของท่านอาจส่งผลกระทบต่อ
                            รูปแบบและการใช้งานบนหน้าเว็บไซต์ของเราได้ หากท่านประสงค์ที่จะทำการปรับเปลี่ยนการตั้งค่า ท่านสามารถตรวจสอบรายละเอียด
                            เพิ่มเติมได้ตามลิงก์ที่ได้ระบุไว้ข้างล่าง </br> </br>
                            <p class="bold-text">Android (Chrome)</p>https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1 
                            <p class="bold-text">Apple Safari</p>https://support.apple.com/en-gb/guide/safari/sfri11471/mac 
                            <p class="bold-text">Blackberry</p>https://docs.blackberry.com/content/dam/docs-blackberry-com/release-pdfs/en/device-user-guides/BlackBerry-Classic-Smartphone-10.3.3-User-Guide-en.pdf 
                            <p class="bold-text">Google Chrome</p>https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en 
                            <p class="bold-text">Microsoft Edge</p>https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd 
                            <p class="bold-text">Microsoft Internet Explorer</p>https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d 
                            <p class="bold-text">Mozilla Firefox</p>https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US 
                            <p class="bold-text">Opera</p>https://help.opera.com/en/latest/web-preferences/ 
                            <p class="bold-text">Iphone or Ipad (Chrome)</p>https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en&oco=1 
                            <p class="bold-text">Iphone or Ipad (Safari)</p>https://support.apple.com/en-us/HT201265 
                            </br></br>
                            ทั้งนี้ โปรดทราบว่า หากท่านเลือกที่จะปิดการใช้งานคุกกี้บนเบราว์เซอร์หรืออุปกรณ์ของท่าน อาจส่งผลกระทบกับการทำงานบางส่วนของเว็บไซต์ของ สศอ. 
                            ที่ไม่สามารถทำงานหรือให้บริการได้เป็นปกติ สศอ. จะไม่รับผิดชอบและ สศอ. ไม่ได้มีความเกี่ยวข้องกับเว็บไซต์ รวมทั้งเนื้อหาในเว็บไซต์ต่าง ๆ 
                            ที่กล่าวมาข้างบน สำหรับข้อมูลอื่น ๆ เพิ่มเติมในเรื่องนี้ ท่านสามารถเข้าไปอ่านได้ที่ https://www.aboutcookies.org/how-to-delete-cookies
                            `,
                    },{
                        title: "การเชื่อมโยงข้อมูลกับเว็บไซต์อื่น",
                        description:
                            `เว็บไซต์ของ สศอ. อาจมีการเชื่อมโยงไปยังเว็บไซต์หรือโซเชียลมีเดียของบุคคลภายนอก รวมถึงอาจมีการฝังเนื้อหาหรือวีดีโอที่มาจากโซเชียลมีเดีย
                             เช่น YouTube หรือ Facebook เป็นต้น ซึ่งจะช่วยให้ท่านเข้าถึงเนื้อหาและสร้างการปฏิสัมพันธ์กับบุคคลอื่นบนโซเชียลมีเดียจากเว็บไซต์ของ สศอ. 
                             ได้ ซึ่งเว็บไซต์หรือโซเชียลมีเดียของบุคคลภายนอกจะมีการกำหนดและตั้งค่าคุกกี้ขึ้นมาเอง โดยที่ สศอ. ไม่สามารถควบคุมหรือรับผิดชอบต่อคุกกี้เหล่านั้นได้ 
                             และขอแนะนำให้ท่านควรเข้าไปอ่านและศึกษานโยบายหรือประกาศการใช้คุกกี้ของบุคคลภายนอกเหล่านั้นด้วย
                            `,
                    },{
                        title: "การเปลี่ยนแปลงประกาศ",
                        description:
                            `ประกาศนี้อาจมีการปรับปรุงให้เหมาะสมและสอดคล้องกับสถานการณ์และตามการให้บริการจริง โดย สศอ. จะมีการแจ้งประกาศที่มีการปรับปรุงใหม่บนเว็บไซต์นี้
                            ดังนั้น สศอ. ขอแนะนำให้ท่านตรวจสอบให้แน่ใจว่าท่านได้เข้าใจการเปลี่ยนแปลงตามข้อกำหนดดังกล่าว
                            `,
                    },
                    {
                        title: "ติดต่อ สศอ.",
                        description:
                            `หากท่านมีข้อสงสัยหรือมีข้อกังวลเกี่ยวกับประกาศนี้ หรือมีคำแนะนำเกี่ยวกับการใช้งานคุกกี้ของหน่วยงาน สศอ. สามารถติดต่อได้ที่
                            </br></br>
                            
                            <span class="bold-text">ที่อยู่ </span>สำนักงานเศรษฐกิจอุตสาหกรรม กระทรวงอุตสาหกรรม
                            75/6 ถนนพระรามที่ 6 แขวงทุ่งพญาไท เขตราชเทวี กรุงเทพมหานคร 10400 </br>
                            <span class="bold-text">โทรศัพท์</span>: 0 2430 6804 ต่อ 680416 </br>
                            <span class="bold-text">โทรสาร</span>: 0 2430 6804 ต่อ 680499 </br>
                            <span class="bold-text">Email</span>: car@oie.go.th
                            `,
                    },
                ],
            },
        },
    },
};

const CookieConsent = () => {
    useEffect(() => {
        const cc = window.initCookieConsent();
        document.body.classList.toggle('theme_funky');
        cc.set("eco_tyre_cookie_consent", "value", { secure: false });
        cc.run(config)
    }, [])

    return null
}

export default CookieConsent;
import React, { Component } from "react";
import axios from "axios";
import {
  Form,
  Icon,
  Button,
  Modal,
  Input,
  Loader,
  Dimmer,
  Segment,
  Grid,
} from "semantic-ui-react";
import { connect } from "react-redux"; // connect redux
import { fetchRequest } from "../actions/actions";
import Swal from "sweetalert2";
import {
  TOKEN_COMPANY,
  SAVE_AS_DRAFT,
  GET_DRAFT_DATA_BY_ID,
  SAVE_FORMDATA,
  SAVE_DRAFT,
  SEND_REQUEST,
  SAVE_REQUEST_FILE,
  EDIT_REQUEST,
  Approval_auto_approve,
} from "../router";
import { Redirect } from "react-router-dom";

const theme_color_1 = "violet";

const requestLicensePath = `/company/request-license`;
const requestDetailPath = `/company/request-detail`;
const requestTestPath = `/company/request-test`;
const requestPreviewPath = `/company/request-preview`;

class requestButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    // // console.log("did mount -> ", this.props.requestRedux);
    await this.setState(this.props.requestRedux);
  };

  componentWillReceiveProps = async (nextPrev) => {
    // // console.log("receive", nextPrev.data);
    // // console.log("will", this.props.data);
    await this.setState(nextPrev.data);
  };

  render() {
    var url = this.props.url;
    var productPos = this.state.productPos;

    // // console.log("button", this.state);
    return (
      <div>
        <span />
        {(() => {
          //   Preview
          let previewButton = (
            <Grid.Column computer={3} tablet={4} floated="left">
              <Button
                fluid
                icon
                labelPosition="left"
                type="button"
                color={theme_color_1}
                // //floated="right"
                onClick={async () => {
                  this.goPreview();
                }}
              >
                แสดงตัวอย่าง
                <Icon name="search" />
              </Button>
            </Grid.Column>
          );

          // Save As
          let saveAsButton = <span />;

          if (this.state.tyre_requests_id) {
            saveAsButton = (
              <Grid.Column computer={3} tablet={4} floated="right">
                <Button
                  fluid
                  id="saveAsButton"
                  icon
                  labelPosition="left"
                  type="button"
                  color={theme_color_1}
                  //floated="right"
                  onClick={async () => {
                    this.setState({
                      modalSave: true,
                    });
                  }}
                >
                  บันทึกฉบับร่างใหม่
                  <Icon name="plus" />
                </Button>
              </Grid.Column>
            );
          }

          //   Save
          let saveButton = <span />;
          if (this.state.tyre_requests_id) {
            saveButton = (
              <Grid.Column computer={3} tablet={2} floated="right">
                <Button
                  fluid
                  icon
                  labelPosition="left"
                  type="button"
                  color={theme_color_1}
                  //floated="right"
                  onClick={async () => {
                    Swal.fire({
                      title: "ต้องการบันทึกข้อมูลหรือไม่?",
                      // text: "You won't be able to revert this!",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#43A047",
                      cancelButtonColor: "#E53935",
                      confirmButtonText: "บันทึก",
                      cancelButtonText: "ยกเลิก",
                    }).then(async (result) => {
                      if (result.value) {
                        await this.setState({
                          modalOnlySave: true,
                          loader_save: true,
                        });

                        await this.saveForm();

                        await this.setState({
                          modalOnlySave: false,
                          loader_save: false,
                        });

                        await this.props.callBackParent(this.state);
                      }
                    });
                  }}
                >
                  {"  "} บันทึก
                  <Icon name="save" />
                </Button>
              </Grid.Column>
            );
          }

          if (
            this.state.params_request_id !== undefined &&
            this.state.params_company_id !== undefined
          ) {
            saveButton = <span></span>;
            saveAsButton = <span></span>;
          }
          switch (url) {
            case "requestLicense":
              //   Next
              let nextButton_preview = (
                <Grid.Column computer={3} tablet={3} floated="right">
                  <Button
                    fluid
                    icon
                    labelPosition="left"
                    type="button"
                    color={theme_color_1}
                    //floated="right"
                    onClick={async () => {
                      if (this.state.tyre_requests_id) {
                        await this.goDetail_license();
                      } else {
                        this.setState({
                          modalSave: true,
                        });
                      }
                    }}
                    // disabled={!this.state.isSaved}
                  >
                    ต่อไป
                    <Icon name="arrow right" />
                  </Button>
                </Grid.Column>
              );

              return (
                <Grid columns="equal" stackable>
                  <Grid.Row>
                    <Grid.Column only="computer tablet" />

                    {previewButton}

                    {saveAsButton}

                    {saveButton}

                    {nextButton_preview}
                  </Grid.Row>
                </Grid>
              );

            case "requestDetail":
              // Back
              let backButton_detail;
              if (this.state.productPos === 0) {
                backButton_detail = (
                  <Grid.Column computer={3} tablet={3} floated="left">
                    <Button
                      fluid
                      icon
                      labelPosition="left"
                      type="button"
                      color={theme_color_1}
                      // floated="left"
                      onClick={async () => {
                        await this.saveRedux();
                        if (
                          this.state.params_company_id === undefined &&
                          this.state.params_request_id === undefined
                        ) {
                          this.props.history.push(requestLicensePath);
                        } else if (
                          this.state.params_company_id !== undefined &&
                          this.state.params_request_id !== undefined
                        ) {
                          this.props.history.push(
                            requestLicensePath +
                              "/" +
                              this.state.params_company_id +
                              "/" +
                              this.state.params_request_id
                          );
                        }
                      }}
                    >
                      ย้อนกลับ
                      <Icon name="arrow left" />
                    </Button>
                  </Grid.Column>
                );
              } else {
                backButton_detail = (
                  <Grid.Column computer={3} tablet={3} floated="left">
                    <Button
                      icon
                      fluid
                      labelPosition="left"
                      type="button"
                      color={theme_color_1}
                      // floated="left"
                      onClick={async () => {
                        await this.setState({
                          productPos: this.state.productPos - 1,
                        });
                        await this.saveRedux();

                        this.props.history.push(requestTestPath);
                      }}
                    >
                      ย้อนกลับ
                      <Icon name="arrow left" />
                    </Button>
                  </Grid.Column>
                );
              }

              //   Next
              let nextButton_detail = (
                <Grid.Column computer={3} tablet={3} floated="left">
                  <Button
                    icon
                    fluid
                    labelPosition="left"
                    type="button"
                    color={theme_color_1}
                    floated="right"
                    onClick={async () => {
                      this.goTest();
                    }}
                  >
                    ต่อไป
                    <Icon name="arrow right" />
                  </Button>
                </Grid.Column>
              );

              return (
                <Grid columns="equal" stackable>
                  <Grid.Row>
                    {backButton_detail}
                    <Grid.Column only="computer" />
                    {previewButton}
                    {saveAsButton}
                    {saveButton}
                    {nextButton_detail}
                  </Grid.Row>
                </Grid>
              );

            case "requestTest":
              // Back
              let backButton_test = (
                <Grid.Column computer={3} tablet={3} floated="left">
                  <Button
                    fluid
                    icon
                    labelPosition="left"
                    type="button"
                    color={theme_color_1}
                    floated="left"
                    onClick={async () => {
                      this.saveRedux();
                      this.props.history.push(requestDetailPath);
                    }}
                  >
                    ย้อนกลับ
                    <Icon name="arrow left" />
                  </Button>
                </Grid.Column>
              );

              //   Next
              let nextButton_test;
              if (this.props.productLength === productPos + 1) {
                // let title_swal;
                let title_button;
                if (
                  this.state.params_request_id === undefined &&
                  this.state.params_company_id === undefined
                ) {
                  // title_swal = "ยืนยันการยื่นขอสติ๊กเกอร์";
                  title_button = "เสร็จสิ้นการกรอกข้อมูล";
                } else {
                  // title_swal = "ยืนยันการแก้ไขสติ๊กเกอร์";
                  title_button = "เสร็จสิ้นการกรอกข้อมูล";
                }

                nextButton_test = (
                  <Grid.Column computer={4} tablet={3} floated="left">
                    <Button
                      fluid
                      icon
                      labelPosition="left"
                      type="button"
                      color={theme_color_1}
                      floated="right"
                      onClick={async () => {
                        // await this.setState({
                        //   modalOnlySave: true,
                        //   loader_save: true
                        // });

                        // Swal.fire({
                        //   title: title_swal,
                        //   type: "warning",
                        //   showCancelButton: true,
                        //   confirmButtonColor: "#43A047",
                        //   cancelButtonColor: "#E53935",
                        //   confirmButtonText: "ตกลง",
                        //   cancelButtonText: "ยกเลิก"
                        // }).then(async result => {
                        //   if (result.value) {
                        //     await this.goSubmit();

                        //     await this.setState({
                        //       modalOnlySave: false,
                        //       loader_save: false
                        //     });
                        //     await this.props.callBackParent(this.state);
                        //   } else {
                        //     await this.setState({
                        //       modalOnlySave: false,
                        //       loader_save: false
                        //     });
                        //     await this.props.callBackParent(this.state);
                        //   }
                        // });
                        // this.goPreview();

                        let submitRequest = await this.validateForm();
                        // this.saveRedux();
                        if (submitRequest) {
                          // this.saveRedux();
                          this.props.history.push({
                            pathname: requestPreviewPath,
                            state: { isCheckingStep: true },
                          });
                        } else {
                          // Swal.fire({
                          //   position: "top",
                          //   title: "เกิดข้อผิดพลาด!",
                          //   text: "กรอกข้อมูลให้ครบถ้วน",
                          //   type: "warning",
                          //   // confirmButtonColor: "red",
                          //   confirmButtonText: "ตกลง"
                          // });
                          // this.props.history.push(requestLicensePath);
                        }
                      }}
                    >
                      {title_button}
                      <Icon name="arrow right" />
                    </Button>
                  </Grid.Column>
                );
              } else {
                nextButton_test = (
                  // go detail button
                  <Grid.Column computer={4} tablet={3} floated="left">
                    <Button
                      fluid
                      icon
                      labelPosition="left"
                      type="button"
                      color={theme_color_1}
                      floated="right"
                      onClick={async () => {
                        await this.setState({
                          productPos: this.state.productPos + 1,
                        });
                        // console.log("button", this.state.productPos);
                        this.goDetail_test();
                      }}
                    >
                      ต่อไป
                      <Icon name="arrow right" />
                    </Button>
                  </Grid.Column>
                );
              }

              return (
                <Grid columns="equal" stackable>
                  <Grid.Row>
                    {backButton_test}
                    {/* <Grid.Column only="computer" /> */}
                    {previewButton}
                    {saveAsButton}
                    {saveButton}
                    {nextButton_test}
                  </Grid.Row>
                </Grid>
              );
            case "requestPreview":
              let title_swal;
              if (
                this.state.params_request_id === undefined &&
                this.state.params_company_id === undefined
              ) {
                title_swal = "ยืนยันการยื่นขอสติ๊กเกอร์";
              } else {
                title_swal = "ยืนยันการแก้ไขสติ๊กเกอร์";
              }

              let backButton_preview = (
                <Button
                  icon
                  labelPosition="left"
                  type="button"
                  size="large"
                  color={"violet"}
                  floated="left"
                  onClick={async () => {
                    this.saveRedux();
                    this.props.history.push(requestTestPath);
                  }}
                >
                  ย้อนกลับ
                  <Icon name="arrow left" />
                </Button>
              );

              let confirmButton = (
                <Button
                  icon
                  labelPosition="left"
                  type="button"
                  size="large"
                  color={"green"}
                  //floated="right"
                  onClick={async () => {
                    // await this.setState({
                    //   modalOnlySave: true,
                    //   loader_save: true,
                    // });

                    Swal.fire({
                      title: title_swal,
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#43A047",
                      cancelButtonColor: "#E53935",
                      confirmButtonText: "ตกลง",
                      cancelButtonText: "ยกเลิก",
                    }).then(async (result) => {
                      if (result.value) {
                        await this.goSubmit();

                        // await this.setState({
                        //   modalOnlySave: false,
                        //   loader_save: false,
                        // });
                        await this.props.callBackParent(this.state);
                        this.props.history.push("/company/main");
                      } else {
                        // await this.setState({
                        //   modalOnlySave: false,
                        //   loader_save: false,
                        // });
                        await this.props.callBackParent(this.state);
                      }
                    });
                    
                    // this.goPreview();
                  }}
                >
                  ยืนยันความถูกต้องของข้อมูล
                  <Icon name="checkmark" />
                </Button>
              );

              return (
                <div>
                  {backButton_preview}
                  {/* {nextButton_preview} */}
                  {confirmButton}
                </div>
              );
            default:
              break;
          }
        })()}

        <Modal size={"mini"} open={this.state.modalOnlySave}>
          <Dimmer active={this.state.loader_save}>
            <Loader size="massive">Loading</Loader>
          </Dimmer>
        </Modal>
        <Modal size={"small"} open={this.state.modalSave} centered={false}>
          <Segment
            padded="very"
            style={{
              border: "rgba(0, 0, 0, 0)",
            }}
          >
            <Dimmer active={this.state.loader_all}>
              <Loader size="massive">Loading</Loader>
            </Dimmer>

            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Modal.Content>
                    <Form>
                      <Form.Field>
                        <label>ชื่อฉบับร่าง</label>

                        <Input
                          placeholder="ชื่อฉบับร่าง"
                          id={"draft_name"}
                          value={this.state.tyre_requests_name || ""}
                          onChange={async (e, { value }) => {
                            await this.setState({
                              tyre_requests_name: value,
                            });
                          }}
                          onKeyPress={(event) => {
                            var code = event.keyCode || event.which;
                            if (code === 13) {
                              this.submitDraft();
                            }
                          }}
                        />
                      </Form.Field>
                    </Form>
                  </Modal.Content>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Modal.Actions>
                    <Button
                      //floated="right"
                      positive
                      icon
                      labelPosition="left"
                      onClick={async () => {
                        this.submitDraft();
                      }}
                    >
                      <Icon name="save" />
                      บันทึกฉบับร่าง
                    </Button>
                    <Button
                      //floated="right"
                      onClick={() => {
                        this.setState({
                          modalSave: false,
                        });
                      }}
                    >
                      ยกเลิก
                    </Button>
                  </Modal.Actions>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal>
      </div>
    );
  }

  goPreview = async () => {
    this.saveRedux();
    this.props.history.push({
      pathname: requestPreviewPath,
      state: { isCheckingStep: false },
    });
  };

  goTest = async () => {
    await this.saveRedux();
    this.props.history.push(requestTestPath);

    // let validResult = await this.props.validate();

    // // // console.log(validResult);
    // if (!validResult) {
    //   //   // console.log(this.state);
    //   this.saveRedux();
    //   this.props.history.push(requestTestPath);
    // }
  };

  goDetail_license = async () => {
    await this.saveRedux();
    this.props.history.push(requestDetailPath);
  };

  goDetail_test = async () => {
    this.saveRedux();

    this.props.history.push(requestDetailPath);

    // let validResult = await this.props.validate();

    // // // console.log(validResult);
    // if (!validResult) {
    //   //   // console.log(this.state);
    //   await this.setState({
    //     productPos: this.state.productPos + 1
    //   });
    //   this.saveRedux();
    //   this.props.history.push(requestDetailPath);
    // }
  };

  // Set State Redux
  saveRedux = async () => {
    // console.log("save redux", this.state);

    await this.props.dispatch(fetchRequest(this.state));
  };

  getDraft = async (tyre_id) => {
    this.setState({ loading: true });

    try {
      // console.log("tyre_id yyyyyyyyyyyyyy", tyre_id);
      let draft_data = await axios.get(GET_DRAFT_DATA_BY_ID + tyre_id);
      let data = draft_data.data.value.tyre_requests;
      // console.log("draft head", data);
      document.title = "ECO Tires " + data.tyre_requests_name;
      this.setState({ draftName: data.tyre_requests_name });
      let productArr = [];
      let preState = this.state;
      let factory =
        data.factories.type_factories_id === "1"
          ? preState.factoryManufacturerTypeOptions
          : data.factories.type_factories_id === "2"
          ? preState.factoryImportTypeOptions
          : null;
      // console.log("factory let", factory);
      // console.log("this state", this.state);

      data.product_requests.map((product, prod_idx) => {
        // console.log("prod_idx", this.state.productRequest[prod_idx]);

        let d = {
          id: product.id,
          productId: product.products_id,
          productTypeId: product.products.type_product_id.toString(),
          productName: product.products.product_struct,
          license: "",
          licenseNumber: product.license_request,
          licenseType: product.selected_tisi_license_type,
          requestNO: product.request_no,
          licenseNumberFile: {
            name: product.file_filename_product_request,
            data: this.state.productRequest[prod_idx].licenseNumberFile.data,
            path: product.file_path_product_request,
          },
          listProduct: product.list_request,
          brandsRequest: product.brand_requests.map((brand, brand_idx) => {
            return {
              id: brand.id,
              brandId: brand.brands_id,
              brandName: brand.brands.name,
              genRequest: brand.gen_requests.map((gen, gen_idx) => {
                return {
                  id: gen.id,
                  genName: gen.gen_name,
                  genLayoutFile: {
                    name: gen.file_filename_gen_request,
                    data: this.state.productRequest[prod_idx].brandsRequest[
                      brand_idx
                    ].genRequest[gen_idx].genLayoutFile.data,
                    path: gen.file_path_gen_request,
                  },
                  sizeRequest: gen.size_requests.map((size, size_idx) => {
                    return {
                      id: size.id,
                      sizeGen: size.size_text,
                      sizeCapacity: size.load_capacity,
                      sizeSpeedSymbol: size.speed_symbol,
                      sizeSpecialFeature: size.special_feature,
                      sizeStructure: size.size_struct,
                      sizeTypeOfUse: size.type_use,
                      sizeCuttingWidth: size.cutting_width,
                      sizeSymbol: size.symbol,
                    };
                  }),
                };
              }),
            };
          }),
          testResult: {
            id: product.test_results.id,
            testName_a: "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
            testValue_a: product.test_results.rs_test_value.toString(),
            testUnit_a: "N/kN",
            brandName_a: product.test_results.rs_brand_request_value,
            genName_a: product.test_results.rs_gen_request_value,
            sizeGen_a: product.test_results.rs_size_request_value,
            testPage_a: product.test_results.rs_test_page,
            testFile_a: {
              name: product.test_results.rs_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_a
                      .data
                  : [],
              path: product.test_results.rs_file_path,
            },

            testName_b: "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
            testValue_b: product.test_results.rg_test_value.toString(),
            testUnit_b: "G",
            brandName_b: product.test_results.rg_brand_request_value,
            genName_b: product.test_results.rg_gen_request_value,
            sizeGen_b: product.test_results.rg_size_request_value,
            testPage_b: product.test_results.rg_test_page,
            testFile_b: {
              name: product.test_results.rg_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_b
                      .data
                  : [],
              path: product.test_results.rg_file_path,
            },

            testName_c: "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
            testValue_c: product.test_results.tr_test_value.toString(),
            testUnit_c: "dB(A)",
            brandName_c: product.test_results.tr_brand_request_value,
            genName_c: product.test_results.tr_gen_request_value,
            sizeGen_c: product.test_results.tr_size_request_value,
            testPage_c: product.test_results.tr_test_page,
            testFile_c: {
              name: product.test_results.tr_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_c
                      .data
                  : [],
              path: product.test_results.tr_file_path,
            },

            testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
            testValue_d: product.test_results.sr_test_value.toString(),
            testUnit_d: "SG",
            brandName_d: product.test_results.sr_brand_request_value,
            genName_d: product.test_results.sr_gen_request_value,
            sizeGen_d: product.test_results.sr_size_request_value,
            testPage_d: product.test_results.sr_test_page,
            testFile_d: {
              name: product.test_results.sr_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_d
                      .data
                  : [],
              path: product.test_results.sr_file_path,
            },

            // testFile: {
            //   name: product.test_results.file_filename_test_result,
            //   data: [],
            //   path: product.test_results.file_path_test_result
            // },
            // testPage: product.test_results.test_page
          },
        };
        return productArr.push(d);
      });

      // console.log("draft id:", data.id);
      preState.tyre_requests_id = data.id;
      // console.log("preState.tyre_requests_id", preState.tyre_requests_id);
      preState.user_type =
        data.factories.type_factories_id === "1"
          ? "ผู้ผลิต"
          : data.factories.type_factories_id === "2"
          ? "ผู้นำเข้า"
          : "";
      preState.productTypeOptions =
        factory !== null
          ? factory.find((f) => f.id === data.factories_id).product_type_options
          : [];
      preState.requestStatus = data.request_status;
      preState.factory = data.factories_id;
      preState.factoryTypeId = data.factories.type_factories_id;
      preState.factoryId = data.factories_id;
      preState.productRequest = productArr;
      preState.updatedAt = data.updated_at;
      // console.log("draft", draft_data.data.value);

      await this.setState(preState);

      // console.log("success", this.state);

      this.setState({
        loading: false,
        isOpenDraft: true,
      });
      return true;
    } catch (error) {
      console.log(error);
      Swal.fire({
        type: "error",
        title: "ผิดพลาด",
        text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
        confirmButtonText: "ตกลง",
      });
      this.setState({ loading: false });
      return false;
    }
  };

  submitDraft = async () => {
    this.setState({ loader_all: true });
    if (await this.saveAsForm()) {
      // this.setState({ isSaved: true })
      Swal.fire({
        position: "top",
        title: "บันทึก!",
        type: "success",
        confirmButtonColor: "#03A9F4",
        confirmButtonText: "ตกลง",
      });
    } else {
      Swal.fire({
        position: "top",
        title: "เกิดข้อผิดพลาด!",
        type: "error",
        confirmButtonColor: "red",
        confirmButtonText: "ตกลง",
      });
    }
    this.setState({
      loader_all: false,
      modalSave: false,
      tyre_requests_name: "",
    });
    await this.props.callBackParent(this.state);
  };

  goSubmit = async () => {
    await this.validateForm();
    let isSendSuccess = false;
    // console.log(this.state);
    await this.props.callBackParent(this.state);

    // if (submitRequest) {
    // console.log("sumbmit");
    if (
      this.state.params_request_id !== undefined &&
      this.state.params_company_id !== undefined
    ) {
      isSendSuccess = await this.editRequest();
    } else {
      isSendSuccess = await this.sendRequest();
    }

    if (isSendSuccess) {
      Swal.fire({
        title: "ยื่นขอสำเร็จ!",
        type: "success",
        confirmButtonText: "ตกลง",
      }).then(async (result) => {
        if (result.value) {
          return <Redirect to="/company/main" />;
        }
      });
    } else {
      Swal.fire({
        position: "top",
        title: "เกิดข้อผิดพลาด!",
        text: "ไม่สามารถทำรายการได้",
        type: "error",
        confirmButtonColor: "red",
        confirmButtonText: "ตกลง",
      });
    }
    // } else {
    //   Swal.fire({
    //     // position: "top",
    //     title: "เกิดข้อผิดพลาด!",
    //     text: "กรอกข้อมูลให้ครบถ้วน",
    //     type: "warning",
    //     // confirmButtonColor: "red",
    //     confirmButtonText: "ตกลง"
    //   });
    // }

    return;
  };

  saveForm = async () => {
    let form = this.state;
    let data;
    let product_requests = [];

    console.log("save form", form);
    form.productRequest.map((product, prod_idx) => {
      let prod = {
        product_requests_index: prod_idx,
        id: product.id,
        tyre_requests_id: form.id,
        products_id: product.productId, //ใส่มา
        license_request: product.licenseNumber,
        list_request: product.listProduct,
        selected_tisi_license_type: product.licenseType,
        request_no: product.requestNO,
        status_uploaded_file_product_request: product.licenseNumberFile.path
          ? 1
          : 0,
        file_path_product_request: product.licenseNumberFile.path,
        file_filename_product_request: product.licenseNumberFile.name,
        file_extension_product_request:
          "." + product.licenseNumberFile.name.split(".").pop(),
        brand_requests: product.brandsRequest.map((brand, brand_idx) => {
          return {
            brand_requests_index: brand_idx,
            id: brand.id,
            product_requests_id: product.id,
            brands_id: parseInt(brand.brandId),
            gen_requests: brand.genRequest.map((gen, gen_idx) => {
              return {
                gen_requests_index: gen_idx,
                id: gen.id,
                brand_requests_id: brand.id,
                gen_name: gen.genName,
                file_path_gen_request: gen.genLayoutFile.path,
                file_filename_gen_request: gen.genLayoutFile.name,
                file_extension_gen_request:
                  "." + gen.genLayoutFile.name.split(".").pop(),
                size_requests: gen.sizeRequest.map((size, size_idx) => {
                  return {
                    size_requests_index: size_idx,
                    id: size.id,
                    gen_requests_id: gen.id,
                    size_text: size.sizeGen,
                    speed_symbol: size.sizeSpeedSymbol,
                    load_capacity: size.sizeCapacity,
                    size_struct: size.sizeStructure,
                    special_feature: size.sizeSpecialFeature,
                    type_use: size.sizeTypeOfUse,
                    cutting_width: size.sizeCuttingWidth,
                    symbol:
                      product.productName.includes("ผ้าใบเฉียง") === true &&
                      product.productTypeId === "3"
                        ? "W"
                        : size.sizeSymbol,
                  };
                }),
              };
            }),
          };
        }),

        test_results: {
          id: product.testResult.id,
          product_requests_id: product.id,
          rs_brand_request_value: product.testResult.brandName_a,
          rs_gen_request_value: product.testResult.genName_a,
          rs_size_request_value: product.testResult.sizeGen_a,
          rs_test_value: product.testResult.testValue_a,
          rs_test_unit: "N/kN",
          rs_test_page: product.testResult.testPage_a,
          rs_file_filename: product.testResult.testFile_a.name,
          rs_file_extension:
            "." + product.testResult.testFile_a.name.split(".").pop(),
          rs_file_path: product.testResult.testFile_a.path,

          rg_brand_request_value: product.testResult.brandName_b,
          rg_gen_request_value: product.testResult.genName_b,
          rg_size_request_value: product.testResult.sizeGen_b,
          rg_test_value: product.testResult.testValue_b,
          rg_test_unit: "G",
          rg_test_page: product.testResult.testPage_b,
          rg_file_filename: product.testResult.testFile_b.name,
          rg_file_extension:
            "." + product.testResult.testFile_b.name.split(".").pop(),
          rg_file_path: product.testResult.testFile_b.path,

          tr_brand_request_value: product.testResult.brandName_c,
          tr_gen_request_value: product.testResult.genName_c,
          tr_size_request_value: product.testResult.sizeGen_c,
          tr_test_value: product.testResult.testValue_c,
          tr_test_unit: "dB(A)",
          tr_test_page: product.testResult.testPage_c,
          tr_file_filename: product.testResult.testFile_c.name,
          tr_file_extension:
            "." + product.testResult.testFile_c.name.split(".").pop(),
          tr_file_path: product.testResult.testFile_c.path,

          sr_brand_request_value: product.testResult.brandName_d,
          sr_gen_request_value: product.testResult.genName_d,
          sr_size_request_value: product.testResult.sizeGen_d,
          sr_test_value: product.testResult.testValue_d,
          sr_test_unit: "SG",
          sr_test_page: product.testResult.testPage_d,
          sr_file_filename: product.testResult.testFile_d.name,
          sr_file_extension:
            "." + product.testResult.testFile_d.name.split(".").pop(),
          sr_file_path: product.testResult.testFile_d.path,

          // test_page: product.testResult.testPage
          // file_path_test_result: product.testResult.testFile.path,
          // file_filename_test_result: product.testResult.testFile.name,
          // file_extension_test_result:
          //   "." + product.testResult.testFile.name.split(".").pop()
        },
      };
      return product_requests.push(prod);
    });

    data = {
      remove_product_requests: form.productRemove,
      remove_brand_requests: form.brandRemove,
      remove_gen_requests: form.genRemove,
      remove_size_requests: form.sizeRemove,
      tyre_requests: {
        id: form.tyre_requests_id,
        tyre_requests_name: form.tyre_requests_name,
        ref_code: "",
        companies_id: form.companyId, //ใส่มา แก้DB companies_id_companies
        factories_id: form.factoryId, //ใส่มา
        type_factories_id: parseInt(form.factoryTypeId), //ใส่มา

        created_at: null,
        updated_at: null,
        deleted_at: null,
        product_requests: product_requests,
      },
    };
    console.log("before save", data);
    try {
      var res = await axios.post(SAVE_DRAFT, data);

      // console.log("SAVE FORM ", data);
      let get_draft_result;
      console.log("RES SAVE ", res);
      if (res.data.message === "success") {
        get_draft_result = await this.getDraft(this.state.tyre_requests_id);

        if (get_draft_result) {
          // console.log('save formdata',res.data.value)
          await this.saveFormData(res.data.value.tyre_requests.id);
        }

        await this.getDraft(this.state.tyre_requests_id);

        // console.log(this.state);
        Swal.fire({
          title: "บันทึก!",
          type: "success",
          confirmButtonColor: "#03A9F4",
          confirmButtonText: "ตกลง",
        });

        return get_draft_result ? true : false;
      } else {
        return false;
      }
    } catch (error) {
      // console.log(error);
      Swal.fire({
        position: "top",
        title: "เกิดข้อผิดพลาด!",
        type: "error",
        confirmButtonColor: "red",
        confirmButtonText: "ตกลง",
      });
      return false;
    }

    // // console.log("save draft res", res);
    // // console.log("save daft data", data);
  };

  saveAsForm = async () => {
    let form = this.state;
    let data;
    let product_requests = [];
    // console.log("save as data", form);
    form.productRequest.map((product, prod_idx) => {
      let prod = {
        product_requests_index: prod_idx,
        id: "",
        tyre_requests_id: "",
        products_id: product.productId, //ใส่มา
        license_request: product.licenseNumber,
        list_request: product.listProduct,
        selected_tisi_license_type: product.licenseType,
        request_no: product.requestNO,
        status_uploaded_file_product_request: product.licenseNumberFile.path
          ? 1
          : 0,
        file_path_product_request: product.licenseNumberFile.path,
        file_filename_product_request: product.licenseNumberFile.name,
        file_extension_product_request:
          "." + product.licenseNumberFile.name.split(".").pop(),
        brand_requests: product.brandsRequest.map((brand, brand_idx) => {
          return {
            brand_requests_index: brand_idx,
            id: "",
            product_requests_id: "",
            brands_id: parseInt(brand.brandId),
            gen_requests: brand.genRequest.map((gen, gen_idx) => {
              return {
                gen_requests_index: gen_idx,
                id: "",
                brand_requests_id: "",
                gen_name: gen.genName,
                file_path_gen_request: gen.genLayoutFile.path,
                file_filename_gen_request: gen.genLayoutFile.name,
                file_extension_gen_request:
                  "." + gen.genLayoutFile.name.split(".").pop(),
                size_requests: gen.sizeRequest.map((size, size_idx) => {
                  return {
                    size_requests_index: size_idx,
                    id: "",
                    gen_requests_id: "",
                    size_text: size.sizeGen,
                    speed_symbol: size.sizeSpeedSymbol,
                    load_capacity: size.sizeCapacity,
                    size_struct: size.sizeStructure,
                    special_feature: size.sizeSpecialFeature,
                    type_use: size.sizeTypeOfUse,
                    cutting_width: size.sizeCuttingWidth,
                    symbol:
                      product.productName.includes("ผ้าใบเฉียง") === true &&
                      product.productTypeId === "3"
                        ? "W"
                        : size.sizeSymbol,
                  };
                }),
              };
            }),
          };
        }),

        test_results: {
          id: product.testResult.id,
          product_requests_id: product.id,
          rs_brand_request_value: product.testResult.brandName_a,
          rs_gen_request_value: product.testResult.genName_a,
          rs_size_request_value: product.testResult.sizeGen_a,
          rs_test_value: product.testResult.testValue_a,
          rs_test_unit: "N/kN",
          rs_test_page: product.testResult.testPage_a,
          rs_file_filename: product.testResult.testFile_a.name,
          rs_file_extension:
            "." + product.testResult.testFile_a.name.split(".").pop(),
          rs_file_path: product.testResult.testFile_a.path,

          rg_brand_request_value: product.testResult.brandName_b,
          rg_gen_request_value: product.testResult.genName_b,
          rg_size_request_value: product.testResult.sizeGen_b,
          rg_test_value: product.testResult.testValue_b,
          rg_test_unit: "G",
          rg_test_page: product.testResult.testPage_b,
          rg_file_filename: product.testResult.testFile_b.name,
          rg_file_extension:
            "." + product.testResult.testFile_b.name.split(".").pop(),
          rg_file_path: product.testResult.testFile_b.path,

          tr_brand_request_value: product.testResult.brandName_c,
          tr_gen_request_value: product.testResult.genName_c,
          tr_size_request_value: product.testResult.sizeGen_c,
          tr_test_value: product.testResult.testValue_c,
          tr_test_unit: "dB(A)",
          tr_test_page: product.testResult.testPage_c,
          tr_file_filename: product.testResult.testFile_c.name,
          tr_file_extension:
            "." + product.testResult.testFile_c.name.split(".").pop(),
          tr_file_path: product.testResult.testFile_c.path,

          sr_brand_request_value: product.testResult.brandName_d,
          sr_gen_request_value: product.testResult.genName_d,
          sr_size_request_value: product.testResult.sizeGen_d,
          sr_test_value: product.testResult.testValue_d,
          sr_test_unit: "SG",
          sr_test_page: product.testResult.testPage_d,
          sr_file_filename: product.testResult.testFile_d.name,
          sr_file_extension:
            "." + product.testResult.testFile_d.name.split(".").pop(),
          sr_file_path: product.testResult.testFile_d.path,

          // test_page: product.testResult.testPage
          // file_path_test_result: product.testResult.testFile.path,
          // file_filename_test_result: product.testResult.testFile.name,
          // file_extension_test_result:
          //   "." + product.testResult.testFile.name.split(".").pop()
        },
      };
      return product_requests.push(prod);
    });

    data = {
      tyre_requests: {
        id: "",
        tyre_requests_name: form.tyre_requests_name,
        ref_code: "",
        companies_id: form.companyId, //ใส่มา แก้DB companies_id_companies
        factories_id: form.factoryId, //ใส่มา
        type_factories_id: parseInt(form.factoryTypeId), //ใส่มา
        created_at: null,
        updated_at: null,
        deleted_at: null,
        product_requests: product_requests,
      },
    };
    let get_draft_result;

    // console.log("data to send ", data);

    try {
      var res = await axios.post(SAVE_AS_DRAFT, data);
      // console.log("save AS draft res", res);

      if (res.data.message === "success") {
        await this.getDraft(res.data.value.tyre_requests.id);

        let save_formdata_result = await this.saveFormData(
          res.data.value.tyre_requests.id
        );

        if (save_formdata_result) {
          get_draft_result = await this.getDraft(this.state.tyre_requests_id);
        }
      }

      // return true;
      if (get_draft_result) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // console.log(error);
      Swal.fire({
        position: "top",
        title: "เกิดข้อผิดพลาด!",
        type: "error",
        confirmButtonColor: "red",
        confirmButtonText: "ตกลง",
      });
      return false;
    }
  };

  sendRequest = async () => {
    let form = this.state;
    let data;
    let product_requests = [];

    // console.log("save form", form);
    form.productRequest.map((product, prod_idx) => {
      let prod = {
        product_requests_index: prod_idx,
        id: product.id,
        tyre_requests_id: form.id,
        products_id: product.productId, //ใส่มา
        license_request: product.licenseNumber,
        list_request: product.listProduct,
        status_uploaded_file_product_request: product.licenseNumberFile.path
          ? 1
          : 0,
        file_path_product_request: product.licenseNumberFile.path,
        file_filename_product_request: product.licenseNumberFile.name,
        file_extension_product_request:
          "." + product.licenseNumberFile.name.split(".").pop(),
        selected_tisi_license_type: product.licenseType,
        request_no: product.requestNO,
        brand_requests: product.brandsRequest.map((brand, brand_idx) => {
          return {
            brand_requests_index: brand_idx,
            id: brand.id,
            product_requests_id: product.id,
            brands_id: brand.brandId,
            gen_requests: brand.genRequest.map((gen, gen_idx) => {
              return {
                gen_requests_index: gen_idx,
                id: gen.id,
                brand_requests_id: brand.id,
                gen_name: gen.genName,
                file_path_gen_request: gen.genLayoutFile.path,
                file_filename_gen_request: gen.genLayoutFile.name,
                file_extension_gen_request:
                  "." + gen.genLayoutFile.name.split(".").pop(),
                size_requests: gen.sizeRequest.map((size, size_idx) => {
                  return {
                    size_requests_index: size_idx,
                    id: size.id,
                    gen_requests_id: gen.id,
                    size_text: size.sizeGen,
                    speed_symbol: size.sizeSpeedSymbol,
                    load_capacity: size.sizeCapacity,
                    size_struct: size.sizeStructure,
                    special_feature: size.sizeSpecialFeature,
                    type_use: size.sizeTypeOfUse,
                    cutting_width: size.sizeCuttingWidth,
                    symbol:
                      product.productName.includes("ผ้าใบเฉียง") === true &&
                      product.productTypeId === "3"
                        ? "W"
                        : size.sizeSymbol,
                  };
                }),
              };
            }),
          };
        }),

        test_results: {
          id: product.testResult.id,
          product_requests_id: product.id,
          rs_brand_request_value: product.testResult.brandName_a,
          rs_gen_request_value: product.testResult.genName_a,
          rs_size_request_value: product.testResult.sizeGen_a,
          rs_test_value: product.testResult.testValue_a,
          rs_test_unit: "N/kN",
          rs_test_page: product.testResult.testPage_a,
          rs_file_filename: product.testResult.testFile_a.name,
          rs_file_extension:
            "." + product.testResult.testFile_a.name.split(".").pop(),
          rs_file_path: product.testResult.testFile_a.path,

          rg_brand_request_value: product.testResult.brandName_b,
          rg_gen_request_value: product.testResult.genName_b,
          rg_size_request_value: product.testResult.sizeGen_b,
          rg_test_value: product.testResult.testValue_b,
          rg_test_unit: "G",
          rg_test_page: product.testResult.testPage_b,
          rg_file_filename: product.testResult.testFile_b.name,
          rg_file_extension:
            "." + product.testResult.testFile_b.name.split(".").pop(),
          rg_file_path: product.testResult.testFile_b.path,

          tr_brand_request_value: product.testResult.brandName_c,
          tr_gen_request_value: product.testResult.genName_c,
          tr_size_request_value: product.testResult.sizeGen_c,
          tr_test_value: product.testResult.testValue_c,
          tr_test_unit: "dB(A)",
          tr_test_page: product.testResult.testPage_c,
          tr_file_filename: product.testResult.testFile_c.name,
          tr_file_extension:
            "." + product.testResult.testFile_c.name.split(".").pop(),
          tr_file_path: product.testResult.testFile_c.path,

          sr_brand_request_value: product.testResult.brandName_d,
          sr_gen_request_value: product.testResult.genName_d,
          sr_size_request_value: product.testResult.sizeGen_d,
          sr_test_value: product.testResult.testValue_d,
          sr_test_unit: "SG",
          sr_test_page: product.testResult.testPage_d,
          sr_file_filename: product.testResult.testFile_d.name,
          sr_file_extension:
            "." + product.testResult.testFile_d.name.split(".").pop(),
          sr_file_path: product.testResult.testFile_d.path,

          // test_page: product.testResult.testPage
          // file_path_test_result: product.testResult.testFile.path,
          // file_filename_test_result: product.testResult.testFile.name,
          // file_extension_test_result:
          //   "." + product.testResult.testFile.name.split(".").pop()
        },
      };
      return product_requests.push(prod);
    });

    data = {
      remove_product_requests: form.productRemove,
      remove_brand_requests: form.brandRemove,
      remove_gen_requests: form.genRemove,
      remove_size_requests: form.sizeRemove,
      tyre_requests: {
        id: form.tyre_requests_id,
        tyre_requests_name: form.tyre_requests_name,
        ref_code: "",
        companies_id: form.companyId, //ใส่มา แก้DB companies_id_companies
        factories_id: form.factoryId, //ใส่มา
        type_factories_id: parseInt(form.factoryTypeId), //ใส่มา

        created_at: null,
        updated_at: null,
        deleted_at: null,
        product_requests: product_requests,
      },
    };

    this.setState({
      modalOnlySave: true,
      loader_save: true,
    });
    try {
     
      var res = await axios.post(SEND_REQUEST, data, TOKEN_COMPANY());

      if (res.data.message.includes("Success")) {
        await this.setStateFromDraft(res.data.value);
        await this.saveFormDataRequest(res.data.value.id);

        var resApprove = await axios.put(Approval_auto_approve, {
          request_id: res.data.value.id,
        });
        this.setState({
          modalOnlySave: false,
          loader_save: false,
        });

        if (resApprove.data.msg.includes("Success")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      this.setState({
        modalOnlySave: false,
        loader_save: false,
      });
      console.log(error.response);

      Swal.fire({
        position: "top",
        title: "เกิดข้อผิดพลาด!",
        text: error.message,
        type: "error",
        confirmButtonColor: "red",
        confirmButtonText: "ตกลง",
      });
      return false;
    }

    // // console.log("save draft res", res);
    // // console.log("save daft data", data);
  };

  editRequest = async () => {
    let form = this.state;
    let data;
    let product_requests = [];

    // console.log("save form", form);
    form.productRequest.map((product, prod_idx) => {
      let prod = {
        product_requests_index: prod_idx,
        id: product.id,
        tyre_requests_id: form.id,
        products_id: product.productId, //ใส่มา
        license_request: product.licenseNumber,
        list_request: product.listProduct,
        file_path_product_request: product.licenseNumberFile.path,
        file_filename_product_request: product.licenseNumberFile.name,
        file_extension_product_request:
          "." + product.licenseNumberFile.name.split(".").pop(),
        brand_requests: product.brandsRequest.map((brand, brand_idx) => {
          return {
            brand_requests_index: brand_idx,
            id: brand.id,
            product_requests_id: product.id,
            brands_id: parseInt(brand.brandId),
            gen_requests: brand.genRequest.map((gen, gen_idx) => {
              return {
                gen_requests_index: gen_idx,
                id: gen.id,
                brand_requests_id: brand.id,
                gen_name: gen.genName,
                file_path_gen_request: gen.genLayoutFile.path,
                file_filename_gen_request: gen.genLayoutFile.name,
                file_extension_gen_request:
                  "." + gen.genLayoutFile.name.split(".").pop(),
                size_requests: gen.sizeRequest.map((size, size_idx) => {
                  return {
                    size_requests_index: size_idx,
                    id: size.id,
                    gen_requests_id: gen.id,
                    size_text: size.sizeGen,
                    speed_symbol: size.sizeSpeedSymbol,
                    load_capacity: size.sizeCapacity,
                    size_struct: size.sizeStructure,
                    special_feature: size.sizeSpecialFeature,
                    type_use: size.sizeTypeOfUse,
                    cutting_width: size.sizeCuttingWidth,
                    symbol:
                      product.productName.includes("ผ้าใบเฉียง") === true &&
                      product.productTypeId === "3"
                        ? "W"
                        : size.sizeSymbol,
                  };
                }),
              };
            }),
          };
        }),

        test_results: {
          id: product.testResult.id,
          product_requests_id: product.id,
          rs_brand_request_value: product.testResult.brandName_a,
          rs_gen_request_value: product.testResult.genName_a,
          rs_size_request_value: product.testResult.sizeGen_a,
          rs_test_value: product.testResult.testValue_a,
          rs_test_unit: "N/kN",
          rs_test_page: product.testResult.testPage_a,
          rs_file_filename: product.testResult.testFile_a.name,
          rs_file_extension:
            "." + product.testResult.testFile_a.name.split(".").pop(),
          rs_file_path: product.testResult.testFile_a.path,

          rg_brand_request_value: product.testResult.brandName_b,
          rg_gen_request_value: product.testResult.genName_b,
          rg_size_request_value: product.testResult.sizeGen_b,
          rg_test_value: product.testResult.testValue_b,
          rg_test_unit: "G",
          rg_test_page: product.testResult.testPage_b,
          rg_file_filename: product.testResult.testFile_b.name,
          rg_file_extension:
            "." + product.testResult.testFile_b.name.split(".").pop(),
          rg_file_path: product.testResult.testFile_b.path,

          tr_brand_request_value: product.testResult.brandName_c,
          tr_gen_request_value: product.testResult.genName_c,
          tr_size_request_value: product.testResult.sizeGen_c,
          tr_test_value: product.testResult.testValue_c,
          tr_test_unit: "dB(A)",
          tr_test_page: product.testResult.testPage_c,
          tr_file_filename: product.testResult.testFile_c.name,
          tr_file_extension:
            "." + product.testResult.testFile_c.name.split(".").pop(),
          tr_file_path: product.testResult.testFile_c.path,

          sr_brand_request_value: product.testResult.brandName_d,
          sr_gen_request_value: product.testResult.genName_d,
          sr_size_request_value: product.testResult.sizeGen_d,
          sr_test_value: product.testResult.testValue_d,
          sr_test_unit: "SG",
          sr_test_page: product.testResult.testPage_d,
          sr_file_filename: product.testResult.testFile_d.name,
          sr_file_extension:
            "." + product.testResult.testFile_d.name.split(".").pop(),
          sr_file_path: product.testResult.testFile_d.path,

          // test_page: product.testResult.testPage
          // file_path_test_result: product.testResult.testFile.path,
          // file_filename_test_result: product.testResult.testFile.name,
          // file_extension_test_result:
          //   "." + product.testResult.testFile.name.split(".").pop()
        },
      };
      return product_requests.push(prod);
    });

    data = {
      remove_product_requests: form.productRemove,
      remove_brand_requests: form.brandRemove,
      remove_gen_requests: form.genRemove,
      remove_size_requests: form.sizeRemove,
      tyre_requests: {
        id: form.tyre_requests_id,
        tyre_requests_name: form.tyre_requests_name,
        ref_code: "",
        companies_id: form.companyId, //ใส่มา แก้DB companies_id_companies
        factories_id: form.factoryId, //ใส่มา
        type_factories_id: parseInt(form.factoryTypeId), //ใส่มา

        created_at: null,
        updated_at: null,
        deleted_at: null,
        product_requests: product_requests,
      },
    };

    try {
      var res = await axios.post(EDIT_REQUEST, data);

      // console.log("SAVE FORM ", data);

      // console.log("RES SAVE ", res.data);
      if (res.data.message === "Success") {
        // console.log("Success", this.state.params_request_id)
        await this.saveFormDataRequest(this.state.params_request_id);

        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        position: "top",
        title: "เกิดข้อผิดพลาด!",
        type: "error",
        confirmButtonColor: "red",
        confirmButtonText: "ตกลง",
      });
      return false;
    }

    // // console.log("save draft res", res);
    // // console.log("save daft data", data);
  };

  saveFormData = async (tyre_id) => {
    // console.log("saveFormData State", this.state.productRequest);
    let formData = new FormData();

    // console.log("form data_id", tyre_id);

    // console.log("SAVE FORM DATA ", this.state);
    formData.append("updated_at", this.state.updatedAt);
    formData.append("companies_id", this.state.companyId);
    // formData.append("tyre_requests_id", this.state.tyre_requests_id);
    formData.append("draft_tyre_requests_id", tyre_id);
    let count_product_requests_file = 0;
    let count_gen_requests_file = 0;
    let count_test_results_file = 0;

    let productRequest = this.state.productRequest;

    // console.log(("productRequest", productRequest));
    productRequest.map((product) => {
      if (product.licenseNumberFile.data.length !== 0) {
        // console.log("product.id", product.id);
        formData.append(
          "product_requests_id" + count_product_requests_file,
          product.id
        );
        formData.append(
          "product_requests_file" + count_product_requests_file,
          product.licenseNumberFile.data
        );
        count_product_requests_file++;
        // // console.log("file licenseNumber", product.licenseNumberFile.data);
      }

      product.brandsRequest.map((brand) => {
        brand.genRequest.map((gen) => {
          if (gen.genLayoutFile.data.length !== 0) {
            // console.log("gen.id ", gen.id);
            console.log("gen file", gen.genLayoutFile);
            formData.append(
              "gen_requests_id" + count_gen_requests_file,
              gen.id
            );
            formData.append(
              "gen_requests_file" + count_gen_requests_file,
              gen.genLayoutFile.data
            );

            count_gen_requests_file++;
          }
          return null;
        });

        return null;
      });
      // if (product.testResult.testFile.data.length !== 0) {
      //   // console.log("test_results_id", product.testResult.id);
      //   formData.append(
      //     "test_results_id" + count_test_results_file,
      //     product.testResult.id
      //   );

      //   formData.append(
      //     "test_results_file" + count_test_results_file,
      //     product.testResult.testFile.data
      //   );
      //   count_test_results_file++;
      // }

      formData.append(
        "test_results_id" + count_test_results_file,
        product.testResult.id
      );

      if (product.testResult.testFile_a.data.length !== 0) {
        formData.append(
          "rs_file" + count_test_results_file,
          product.testResult.testFile_a.data
        );
      }

      if (product.testResult.testFile_b.data.length !== 0) {
        formData.append(
          "rg_file" + count_test_results_file,
          product.testResult.testFile_b.data
        );
      }

      if (product.testResult.testFile_c.data.length !== 0) {
        formData.append(
          "tr_file" + count_test_results_file,
          product.testResult.testFile_c.data
        );
      }

      if (product.testResult.testFile_d.data.length !== 0) {
        formData.append(
          "sr_file" + count_test_results_file,
          product.testResult.testFile_d.data
        );
      }

      return count_test_results_file++;
    });
    formData.append("count_product_requests_file", count_product_requests_file);
    formData.append("count_gen_requests_file", count_gen_requests_file);
    formData.append("count_test_results_file", count_test_results_file);

    // for (var a of formData.entries()) {
    // console.log("in form data", a[0] + ", ", a[1]);
    // }
    // console.log("formData", formData.entries());

    try {
      var { data } = await axios.post(SAVE_FORMDATA, formData);

      // console.log("file data", data, "status", status);

      if (data.message === "success") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        position: "top",
        title: "เกิดข้อผิดพลาด!",
        type: "error",
        confirmButtonColor: "red",
        confirmButtonText: "ตกลง",
      });
      return false;
    }
  };

  saveFormDataRequest = async (tyre_id) => {
    let formData = new FormData();

    // console.log("SAVE FORM DATA ", this.state);
    formData.append("updated_at", this.state.updatedAt);
    formData.append("companies_id", this.state.companyId);
    // formData.append("tyre_requests_id", this.state.tyre_requests_id);
    formData.append("tyre_requests_id", tyre_id);
    let count_product_requests_file = 0;
    let count_gen_requests_file = 0;
    let count_test_results_file = 0;
    let productRequest = this.state.productRequest;

    // console.log(("productRequest", productRequest));
    productRequest.map((product) => {
      if (product.licenseNumberFile.data.length !== 0) {
        // console.log("product.id", product.id);
        formData.append(
          "product_requests_id" + count_product_requests_file,
          product.id
        );
        formData.append(
          "product_requests_file" + count_product_requests_file,
          product.licenseNumberFile.data
        );
        count_product_requests_file++;
        // console.log("file licenseNumber", product.licenseNumberFile.data);
      }

      product.brandsRequest.map((brand) => {
        brand.genRequest.map((gen) => {
          if (gen.genLayoutFile.data.length !== 0) {
            // console.log("gen.id ", gen.id);
            formData.append(
              "gen_requests_id" + count_gen_requests_file,
              gen.id
            );
            formData.append(
              "gen_requests_file" + count_gen_requests_file,
              gen.genLayoutFile.data
            );

            count_gen_requests_file++;
          }

          return null;
        });

        return null;
      });
      // if (product.testResult.testFile.data.length !== 0) {
      //   // console.log("product.testResult.id", product.testResult.id);
      //   formData.append(
      //     "test_results_id" + count_test_results_file,
      //     product.testResult.id
      //   );

      //   formData.append(
      //     "test_results_file" + count_test_results_file,
      //     product.testResult.testFile.data
      //   );
      //   count_test_results_file++;
      // }

      formData.append(
        "test_results_id" + count_test_results_file,
        product.testResult.id
      );

      if (product.testResult.testFile_a.data.length !== 0) {
        formData.append(
          "rs_file" + count_test_results_file,
          product.testResult.testFile_a.data
        );
      }

      if (product.testResult.testFile_b.data.length !== 0) {
        formData.append(
          "rg_file" + count_test_results_file,
          product.testResult.testFile_b.data
        );
      }

      if (product.testResult.testFile_c.data.length !== 0) {
        formData.append(
          "tr_file" + count_test_results_file,
          product.testResult.testFile_c.data
        );
      }

      if (product.testResult.testFile_d.data.length !== 0) {
        formData.append(
          "sr_file" + count_test_results_file,
          product.testResult.testFile_d.data
        );
      }
      return count_test_results_file++;
    });
    formData.append("count_product_requests_file", count_product_requests_file);
    formData.append("count_gen_requests_file", count_gen_requests_file);
    formData.append("count_test_results_file", count_test_results_file);

    // for (var a of formData.entries()) {
    // console.log("in form data", a[0] + ", ", a[1]);
    // }
    // console.log("formData", formData.entries());

    try {
      var { data } = await axios.post(SAVE_REQUEST_FILE, formData);

      // console.log("file data", data);

      if (data.message === "success") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        position: "top",
        title: "เกิดข้อผิดพลาด!",
        type: "error",
        confirmButtonColor: "red",
        confirmButtonText: "ตกลง",
      });
      return false;
    }
  };

  setStateFromDraft = async (requestData) => {
    this.setState({ loading: true });

    // // console.log("tyre_id yyyyyyyyyyyyyy", tyre_id);

    let productArr = [];
    let preState = this.state;

    // requestData.factories.type_factories_id === "1"
    //     ? preState.factoryManufacturerTypeOptions
    //     : requestData.factories.type_factories_id === "2"
    //     ? preState.factoryImportTypeOptions
    //     : null;
    // // console.log("factory let", factory);
    // console.log('send req', this.state);

    requestData.product_requests.map((product, prod_idx) => {
      // console.log("product_id", product.products_id);

      let d = {
        id: product.id,
        productId: product.products_id,
        // productTypeId: product.products.type_product_id.toString(),
        // productName: product.products.product_struct,
        license: "",
        licenseNumber: product.license_request,
        licenseNumberFile: {
          name: product.file_filename_product_request,
          data: this.state.productRequest[prod_idx].licenseNumberFile.data,
          path: product.file_path_product_request,
        },
        listProduct: product.list_request,
        brandsRequest: product.brand_requests.map((brand, brand_idx) => {
          return {
            id: brand.id,
            brandId: brand.brands_id,
            // brandName: brand.brands.name,
            genRequest: brand.gen_requests.map((gen, gen_idx) => {
              return {
                id: gen.id,
                genName: gen.gen_name,
                genLayoutFile: {
                  name: gen.file_filename_gen_request,
                  data: this.state.productRequest[prod_idx].brandsRequest[
                    brand_idx
                  ].genRequest[gen_idx].genLayoutFile.data,
                  path: gen.file_path_gen_request,
                },
                sizeRequest: gen.size_requests.map((size) => {
                  return {
                    id: size.id,
                    sizeGen: size.size_text,
                    sizeCapacity: size.load_capacity,
                    sizeSpeedSymbol: size.speed_symbol,
                    sizeSpecialFeature: size.special_feature,
                    sizeStructure: size.size_struct,
                    sizeTypeOfUse: size.type_use,
                    sizeCuttingWidth: size.cutting_width,
                    sizeSymbol: size.symbol,
                  };
                }),
              };
            }),
          };
        }),
        testResult: {
          id: product.test_results.id,
          testName_a: "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
          testValue_a: product.test_results.rs_test_value.toString(),
          testUnit_a: "N/kN",
          brandName_a: product.test_results.rs_brand_request_value,
          genName_a: product.test_results.rs_gen_request_value,
          sizeGen_a: product.test_results.rs_size_request_value,
          testPage_a: product.test_results.rs_test_page,
          testFile_a: {
            name: product.test_results.rs_file_filename,
            data:
              this.state.productRequest[prod_idx] !== undefined
                ? this.state.productRequest[prod_idx].testResult.testFile_a.data
                : [],
            path: product.test_results.rs_file_path,
          },

          testName_b: "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
          testValue_b: product.test_results.rg_test_value.toString(),
          testUnit_b: "G",
          brandName_b: product.test_results.rg_brand_request_value,
          genName_b: product.test_results.rg_gen_request_value,
          sizeGen_b: product.test_results.rg_size_request_value,
          testPage_b: product.test_results.rg_test_page,
          testFile_b: {
            name: product.test_results.rg_file_filename,
            data:
              this.state.productRequest[prod_idx] !== undefined
                ? this.state.productRequest[prod_idx].testResult.testFile_b.data
                : [],
            path: product.test_results.rg_file_path,
          },

          testName_c: "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
          testValue_c: product.test_results.tr_test_value.toString(),
          testUnit_c: "dB(A)",
          brandName_c: product.test_results.tr_brand_request_value,
          genName_c: product.test_results.tr_gen_request_value,
          sizeGen_c: product.test_results.tr_size_request_value,
          testPage_c: product.test_results.tr_test_page,
          testFile_c: {
            name: product.test_results.tr_file_filename,
            data:
              this.state.productRequest[prod_idx] !== undefined
                ? this.state.productRequest[prod_idx].testResult.testFile_c.data
                : [],
            path: product.test_results.tr_file_path,
          },

          testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
          testValue_d: product.test_results.sr_test_value.toString(),
          testUnit_d: "SG",
          brandName_d: product.test_results.sr_brand_request_value,
          genName_d: product.test_results.sr_gen_request_value,
          sizeGen_d: product.test_results.sr_size_request_value,
          testPage_d: product.test_results.sr_test_page,
          testFile_d: {
            name: product.test_results.sr_file_filename,
            data:
              this.state.productRequest[prod_idx] !== undefined
                ? this.state.productRequest[prod_idx].testResult.testFile_d.data
                : [],
            path: product.test_results.sr_file_path,
          },

          // testFile: {
          //   name: product.test_results.file_filename_test_result,
          //   data: [],
          //   path: product.test_results.file_path_test_result
          // },
          // testPage: product.test_results.test_page
        },
      };
      return productArr.push(d);
    });

    preState.tyre_requests_id = requestData.id;

    preState.productRequest = productArr;
    preState.updatedAt = requestData.updated_at;
    // // console.log("draft", draft_data.data.value);

    await this.setState(preState);

    // console.log("success", this.state);

    this.setState({ loading: false });
    return;
  };

  validateForm = async () => {
    var form = this.state;
    var tmp = this.state.productRequest;
    // // console.log("validate form Test", tmp);

    var valid_detail = 0;
    var count_detail = 0;

    var valid_test = 0;
    var count_test = 0;

    var count_license = 0;

    var index_count = -1;
    // // console.log("license", form);
    if (form.user_type === "") {
      form.user_type_error = true;
      count_license++;
    } else {
      form.user_type_error = false;
    }

    if (form.factory === "") {
      form.factory_error = true;
    } else {
      form.factory_error = false;
    }

    if (form.productRequest[0].productName === "") {
      form.product_type_error = true;
    } else {
      form.product_type_error = false;
    }

    if (form.productRequest) await this.setState(form);

    // if (form.user_type_error || form.factory_error || form.product_type_error) {
    //   console.log('push to license')
    //   this.props.history.push(requestLicensePath);
    //   return false
    // }

    tmp.forEach((product, prod_idx) => {
      if (product.brandsRequest.length === 0) {
        tmp[prod_idx].brand_name_error = true;
      } else {
        tmp[prod_idx].brand_name_error = false;
        count_license++;
      }

      // ************************************************ end license validate ************************************************************

      // รายการที่
      if (product.licenseType === 1) {
        if (product.licenseNumberFile.name === "") {
          tmp[prod_idx].licenseNumberFileError = true;
        } else {
          count_detail++;
          tmp[prod_idx].licenseNumberFileError = false;
        }
        if (product.listProduct === "") {
          tmp[prod_idx].listProductError = true;
        } else {
          count_detail++;
          tmp[prod_idx].listProductError = false;
        }

        // เลขที่ใบอนุญาต
        if (product.licenseNumber === "") {
          tmp[prod_idx].licenseNumberError = true;
        } else {
          count_detail++;
          tmp[prod_idx].licenseNumberError = false;
        }
        valid_detail += 3;
      } else {
        if (product.requestNO === "") {
          tmp[prod_idx].requestNOError = true;
        } else {
          count_detail++;
          tmp[prod_idx].requestNOError = false;
        }
        valid_detail += 1;
      }

      for (
        let brand_index = product.brandsRequest.length - 1;
        brand_index >= 0;
        brand_index--
      ) {
        let trade = product.brandsRequest[brand_index];
        for (
          let gen_index = trade.genRequest.length - 1;
          gen_index >= 0;
          gen_index--
        ) {
          let model = trade.genRequest[gen_index];

          for (
            let size_index = model.sizeRequest.length - 1;
            size_index >= 0;
            size_index--
          ) {
            let size = model.sizeRequest[size_index];

            if (
              product.productName.includes("ผ้าใบเฉียง") === true &&
              product.productTypeId === "3"
            ) {
              valid_detail--;
            } else {
              // เครื่องหมาย
              if (size.sizeSymbol === "") {
                tmp[prod_idx].brandsRequest[brand_index].genRequest[
                  gen_index
                ].sizeRequest[size_index].sizeSymbolError = true;
              } else {
                count_detail++;
                tmp[prod_idx].brandsRequest[brand_index].genRequest[
                  gen_index
                ].sizeRequest[size_index].sizeSymbolError = false;
              }
            }

            // ความกว้างภาคตัดระบุ
            if (product.productTypeId === "1") {
              if (size.sizeCuttingWidth === "") {
                tmp[prod_idx].brandsRequest[brand_index].genRequest[
                  gen_index
                ].sizeRequest[size_index].sizeCuttingWidthError = true;
              } else {
                count_detail++;
                tmp[prod_idx].brandsRequest[brand_index].genRequest[
                  gen_index
                ].sizeRequest[size_index].sizeCuttingWidthError = false;
              }
            } else {
              valid_detail--;
            }

            // ประเภทใช้งาน
            if (size.sizeTypeOfUse === "") {
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeTypeOfUseError = true;
            } else {
              count_detail++;
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeTypeOfUseError = false;
            }

            // โครงสร้าง
            if (size.sizeStructure === "") {
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeStructureError = true;
            } else {
              count_detail++;
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeStructureError = false;
            }

            // คุณลักษณะพิเศษ
            if (size.sizeSpecialFeature === "") {
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeSpecialFeatureError = true;
            } else {
              count_detail++;
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeSpecialFeatureError = false;
            }

            // ดัชนีความสามารถการรับโหลด
            if (size.sizeCapacity === "") {
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeCapacityError = true;
            } else {
              count_detail++;
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeCapacityError = false;
            }

            // สัญลักษณ์ความเร็ว
            if (size.sizeSpeedSymbol === "") {
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeSpeedSymbolError = true;
            } else {
              count_detail++;
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeSpeedSymbolError = false;
            }

            // ขนาด
            if (size.sizeGen === "") {
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeGenError = true;
            } else {
              count_detail++;
              tmp[prod_idx].brandsRequest[brand_index].genRequest[
                gen_index
              ].sizeRequest[size_index].sizeGenError = false;
            }

            valid_detail += 8;
          }

          // แบบทางวิศวกรรม
          if (model.genLayoutFile.name === "") {
            tmp[prod_idx].brandsRequest[brand_index].genRequest[
              gen_index
            ].genLayoutFileError = true;
          } else {
            count_detail++;
            tmp[prod_idx].brandsRequest[brand_index].genRequest[
              gen_index
            ].genLayoutFileError = false;
          }

          // รุ่น
          if (model.genName === "") {
            tmp[prod_idx].brandsRequest[brand_index].genRequest[
              gen_index
            ].genNameError = true;
          } else {
            count_detail++;
            tmp[prod_idx].brandsRequest[brand_index].genRequest[
              gen_index
            ].genNameError = false;
          }
          valid_detail += 2;
        }
      }

      // ************************************************ end detail validate ************************************************************
      // var count_test = 0
      // testPage
      // if (product.testResult.testPage === "") {
      //   product.testResult.testPage_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.testPage_error = false;
      // }

      // // testFile
      // if (product.testResult.testFile.name === "") {
      //   product.testResult.testFile_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.testFile_error = false;
      // }

      // // --------------------------------------------------------------------------------------------------

      // testValue_d
      if (product.testResult.testValue_d === "") {
        product.testResult.testValue_d_error = true;
      } else {
        count_test++;
        product.testResult.testValue_d_error = false;
      }

      // // sizeGen_d
      // if (product.testResult.sizeGen_d === "") {
      //   product.testResult.sizeGen_d_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.sizeGen_d_error = false;
      // }

      // // genName_d
      // if (product.testResult.genName_d === "") {
      //   product.testResult.genName_d_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.genName_d_error = false;
      // }

      // // brandName_d
      // if (product.testResult.brandName_d === "") {
      //   product.testResult.brandName_d_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.brandName_d_error = false;
      // }

      // // --------------------------------------------------------------------------------------------------

      // testValue_c
      if (product.testResult.testValue_c === "") {
        product.testResult.testValue_c_error = true;
      } else {
        count_test++;
        product.testResult.testValue_c_error = false;
      }

      // // sizeGen_c
      // if (product.testResult.sizeGen_c === "") {
      //   product.testResult.sizeGen_c_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.sizeGen_c_error = false;
      // }

      // // genName_c
      // if (product.testResult.genName_c === "") {
      //   product.testResult.genName_c_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.genName_c_error = false;
      // }

      // // brandName_c
      // if (product.testResult.brandName_c === "") {
      //   product.testResult.brandName_c_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.brandName_c_error = false;
      // }

      // // --------------------------------------------------------------------------------------------------

      // testValue_b
      if (product.testResult.testValue_b === "") {
        product.testResult.testValue_b_error = true;
      } else {
        count_test++;
        product.testResult.testValue_b_error = false;
      }

      // // sizeGen_b
      // if (product.testResult.sizeGen_b === "") {
      //   product.testResult.sizeGen_b_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.sizeGen_b_error = false;
      // }

      // // genName_b
      // if (product.testResult.genName_b === "") {
      //   product.testResult.genName_b_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.genName_b_error = false;
      // }

      // // brandName_b
      // if (product.testResult.brandName_b === "") {
      //   product.testResult.brandName_b_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.brandName_b_error = false;
      // }

      // // --------------------------------------------------------------------------------------------------

      // testValue_a
      if (product.testResult.testValue_a === "") {
        product.testResult.testValue_a_error = true;
      } else {
        count_test++;
        product.testResult.testValue_a_error = false;
      }

      // // sizeGen_a
      // if (product.testResult.sizeGen_a === "") {
      //   product.testResult.sizeGen_a_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.sizeGen_a_error = false;
      // }

      // // genName_a
      // if (product.testResult.genName_a === "") {
      //   product.testResult.genName_a_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.genName_a_error = false;
      // }

      // // brandName_a
      // if (product.testResult.brandName_a === "") {
      //   product.testResult.brandName_a_error = true;
      // } else {
      //   count_test++;
      //   product.testResult.brandName_a_error = false;
      // }

      valid_test += 4;

      if (valid_detail !== count_detail || valid_test !== count_test) {
        if (index_count === -1) {
          index_count = index_count === -1 ? prod_idx : index_count;
          tmp[prod_idx].formError = valid_detail !== count_detail;
          tmp[prod_idx].testResult.formError = valid_test !== count_test;
          this.setState({
            productPos: index_count,
            productRequest: tmp,
            validForms: form.user_type_error,
          });
          this.saveRedux();
          // console.log('invalid form productPos', this.state.productPos)

          if (valid_detail !== count_detail) {
            this.props.history.push(requestDetailPath);
          } else {
            this.props.history.push(requestTestPath);
            this.props.callBackParent(this.state);
          }
        }
      } else {
        tmp[prod_idx].formError = false;
      }
    });

    if (count_license === form.productRequest.length) {
      this.setState({
        validForms: true,
      });
    } else {
      this.setState({
        validForms: false,
      });
      this.props.history.push(requestLicensePath);
    }

    await this.setState({
      productRequest: tmp,
    });
    // --------------------------------------------------------------------------------------------------

    // valid all and return

    if (
      !this.state.product_type_error &&
      !this.state.factory_error &&
      !this.state.user_type_error &&
      count_license === form.productRequest.length &&
      valid_detail === count_detail &&
      valid_test === count_test
    ) {
      return true;
    } else {
      // this.setState({
      //   productPos: index_count
      // });
      // this.saveRedux();
      // if (this.state.product_type_error || this.state.factory_error || this.state.user_type_error || count_license !== form.productRequest.length) {
      //   this.props.history.push(requestLicensePath);
      // } else if (valid_detail !== count_detail) {
      //   this.props.history.push(requestDetailPath);
      // } else {
      //   this.props.history.push(requestTestPath);

      // }
      // this.props.history.push(requestLicensePath);
      return false;
    }
  };
}

const mapStateToProps = function (state) {
  return {
    requestRedux: state.requestRedux,
  };
};
export default connect(mapStateToProps)(requestButton);

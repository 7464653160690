// import Axios from "axios";

export const reduxSource = (value = []) => ({
	type: 'submit',
	value
});

export const fetchRequest = (value = []) => ({
	type: 'fetchRequest',
	value
});

export const changeFormProductImport = (value = []) => ({
	type: 'changeFormProductImport',
	value
});

export const changeErrorsFormProductImport = (value = []) => ({
	type: 'changeErrorsFormProductImport',
	value
});

export const changeFormPersonAuthorized = (value = []) => ({
	type: 'changeFormPersonAuthorized',
	value
});

export const changeErrorsFormPersonAuthorized = (value = []) => ({
	type: 'changeErrorsFormPersonAuthorized',
	value
});

export const tempAddressFormPersonAuthorized = (value = []) => ({
	type: 'tempAddressFormPersonAuthorized',
	value
});
export const tempAddressFormProductRegister = (value = []) => ({
	type: 'tempAddressFormProductRegister',
	value
});
export const tempAddressFormProductImport = (value = []) => ({
	type: 'tempAddressFormProductImport',
	value
});

export const setResponseValue = (value = []) => ({
	type: 'setResponseValue',
	value
});

export const setBackwardProductImport = () => ({
	type: 'setBackwardProductImport'
});

export const setDefaultDelegateData = () => ({
	type: 'setDefaultDelegateData'
});

export const fetchApproval = (value = []) => ({
	type: 'fetchApproval',
	value
})


export const setApprovedStatus = (value = []) => ({
	type: 'setApprovedStatus',
	value
});

export const setDefaultStore = () => ({
	type: 'setDefaultStore',
	value: {
		edit_form: false,
		company_id_edit_form: '',

		entrepreneurRegister: false,
		productRegister: false,
		productImport: false,
		addDelegate: false,

		// Menu Status
		status_entrepreneur: false,
		status_productRegister: false,
		status_productImport: false,
		status_authorized: false,
		status_delegate: false,

		///// JuristicRegister.js ######################################
		entrepreneurFields: {
			name_prefixTH_value: '1',
			name_prefixTH_index: 0,
			name_prefixTH_text: 'นาย',
			name_prefixEN_value: '1',
			name_prefixEN_index: 0,
			name_prefixEN_text: 'Mr.',
			personality_value: 1,
			personality_index: 0,
			personality_text: 'บุคคลธรรมดา',
			personal_nameTH: '',
			personal_nameEN: '',
			name_juristicTH: '',
			name_juristicEN: '',
			name_limited_partnershipTH: '',
			name_limited_partnershipEN: '',
			name_company_limitedTH: '',
			name_company_limitedEN: '',
			name_public_companyTH: '',
			name_public_companyEN: '',
			entrepreneur_type: [],
			companyType_text: '',
			status_manufacture: 0,
			status_import: 0,
			tax_id: '',
			tax_id_show: '',
			tradeMark_id: '',
			address_id: '',
			alley: '',
			road: '',
			province: '',
			district: '',
			sub_district: '',
			zip_code: '',
			phone_number: '',
			fax_number: '',
			website: '',
			file_corporation_name: '',
			file_vat_name: '',
			file_trademark_name: '',
			juristicTypeTH_value: '1',
			juristicTypeTH_index: 0,
			juristicTypeTH_text: 'ห้างหุ้นส่วนสามัญนิติบุคคล',
			juristicTypeEN_value: '1',
			juristicTypeEN_index: 0,
			juristicTypeEN_text: 'Juristic Ordinary Partnership'
		},
		file_corporation_certificate: { name: '', data: [], path: '' }, //ไม่ตรวจสอบ
		file_vat_certificate: { name: '', data: [], path: '' }, //ไม่ตรวจสอบ
		file_trademark: { name: '', data: [], path: '' }, //ไม่ตรวจสอบ

		///// productRegister.js ######################################
		factory_pos: 0,
		product_type_options: [],
		formProductError: false,
		remarks: [], // เก็บข้อมูล remark ในรูปของ array
		selectedId: null, // id ที่ถูกเลือก
		remarkInput: "", // ค่า remark ที่กำลังกรอกใน modal
		isModalOpen: false, // เปิด/ปิด modal
		remarks_import: [], // เก็บข้อมูล remark ในรูปของ array
		selectedId_import: null, // id ที่ถูกเลือก
		remarkInput_import: "", // ค่า remark ที่กำลังกรอกใน modal
		isModalOpen_import: false, // เปิด/ปิด modal
		factory_form_obj: [
			{
				factories_id: '',
				factory_name_th: '',
				factory_name_en: '',
				factory_license_number: '',
				factory_certificate: '',
				factory_expire_certificate: '',
				factory_from: '',
				factory_under: '',
				factory_location: '',
				factory_number: '',
				factory_alley: '',
				factory_road: '',
				factory_province: '',
				factory_district: '',
				factory_zone: '',
				factory_zip_code: '',
				factory_tel: '',
				factory_license_file: { name: '', data: [], path: '' },
				factory_certificate_file: { name: '', data: [], path: '' },
				factory_product: [
					{
						product_id: '',
						product_name: '',
						product_structure: '',
						product_power_output: '',
						product_trade_name: [
							{
								brand_id: '',
								trade_name: ''
							}
						]
					}
				],
				isIndustrial: 'out',
				posIndustrial: ''
			}
		],
		index_factory_location_options: [ 0 ],
		///// addDelegate.js ######################################
		delegateFields: {
			person_id: '',
			// name_prefixTH_index: 0,
			// name_prefixEN_index: 0,
			// name_prefixTH_text: 'นาย',
			// name_prefixEN_text: 'Mr.',
			nationality_value: 1,
			nationality_index: 0,
			nationality_text: 'ไทย',
			delegate_nameTH: '',
			delegate_nameEN: '',
			delegate_id_number: '',
			delegate_passport_number: '',
			delegate_phone_number: '',
			dataprovince: '',
			datadistrict: '',
			dataSubDistrict: '',
			zip_code: '',
			foreigner_address: '',
			delegate_email: ''
		},
		delegateFields_id: '',
		delegate_id_number: '',
		nationality_value: 1,
		nationality_index: 0,
		file_idcard: { name: '', data: [], path: '' },
		file_passport: { name: '', data: [], path: '' },
		file_poa: { name: '', data: [], path: '' },

		// register confirm ##################################

		// activeItem: 'ข้อมูลผู้ประกอบการ',

		formProductImport: [
			{
				factory: {
					factories_id: '',
					nameFactory: '',
					certificate: '',
					expire_certificate: '',
					from: '',
					under: '',
					location: '',
					country: '',
					file_doc: ''
				},
				productImport: [
					{
						product_id: '',
						productType: '',
						productStructure: '',
						importCapacity: '',
						productName: [
							{
								brand_id: '',
								name: ''
							}
						]
					}
				],
				exportPerson: [
					{
						export_person_id: '',
						exportPersonName: '',
						exportPersonCountry: ''
					}
				],
				warehouse: [
					{
						factory_import_id: '',
						address_id: '',
						alley: '',
						road: '',
						sub_district: '',
						district: '',
						province: '',
						zip_code: ''
					}
				]
			}
		],

		errorsFormProductImport: {
			factory: {
				nameFactory: false,
				certificate: false,
				expire_certificate: false,
				from: false,
				under: false,
				location: false,
				country: false,
				file_doc: false
			},
			productImport: [
				{
					productType: false,
					productStructure: false,
					importCapacity: false,
					productName: [ false ]
				}
			],
			exportPerson: [
				{
					exportPersonName: false,
					exportPersonCountry: false
				}
			],
			warehouse: [
				{
					address_id: false,
					alley: false,
					road: false,
					sub_district: false,
					district: false,
					province: false,
					zip_code: false
				}
			]
		},

		personAuthorized: [
			{
				person_id: '',
				nationality: 1,
				idNumber: '',
				nameTH: '',
				nameEN: '',
				address: '',
				address_id: '',
				alley: '',
				road: '',
				sub_district: '',
				district: '',
				province: '',
				zip_code: '',
				phoneNumber: '',
				email: '',
				file: '',
				type: 1
			}
		],

		errorPersonAuthorized: [
			{
				nationality: false,
				idNumber: false,
				nameTH: false,
				nameEN: false,
				address: false,
				address_id: false,
				alley: false,
				road: false,
				sub_district: false,
				district: false,
				province: false,
				zip_code: false,
				phoneNumber: false,
				email: false,
				file: false
			}
		],

		optionAddressProductRegister: {
			dataprovince: [],
			datadistrict: [],
			dataSubDistrict: []
		},
		optionAddressProductImport: [
			{
				dataprovince: [],
				datadistrict: [],
				dataSubDistrict: []
			}
		],
		optionAddressDropdown: {
			dataprovince: [],
			datadistrict: [],
			dataSubDistrict: []
		},

		dataprovince: [],
		datadistrict: [],
		dataSubDistrict: [],

		forwardProductImport: true
	}
});

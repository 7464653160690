import Axios from "axios";

// //env prod
const HOST_TYRE_PROD = "https://tyre.go.th/"
const HOST_TYRE_API_PROD = "https://tyre.go.th/api"
const HOST_TYRE_API_V1_PROD = `${HOST_TYRE_API_PROD}/api/v1`
const HOST_TYRE_PATH_FILE_PROD = `${HOST_TYRE_API_PROD}/api/v1`

const HOST_CAR_PUBLIC_PROD = "https://car.go.th"
const HOST_CAR_PUBLIC_API_PROD = "https://car.go.th/api"
const HOST_CAR_PUBLIC_API_V1_PROD = `${HOST_CAR_PUBLIC_API_PROD}/v1`

const HOST_ASSESSMENT_PROD = "https://car.go.th/assessment-form"
const HOST_ASSESSMENT_API_PROD = "https://car.go.th/eco-sticker"
const HOST_ASSESSMENT_API_V1_PROD = `${HOST_ASSESSMENT_API_PROD}/api/v1`

// //env test uat
const HOST_TYRE_TEST = "http://uat.car.go.th/tyre"
const HOST_TYRE_API_TEST = "http://uat.car.go.th/tyre-backend"
const HOST_TYRE_API_V1_TEST = `${HOST_TYRE_API_TEST}/api/v1`
const HOST_TYRE_PATH_FILE_TEST = `${HOST_TYRE_API_TEST}/api/v1`

const HOST_CAR_PUBLIC_TEST = "http://uat.car.go.th/car"
const HOST_CAR_PUBLIC_API_TEST = "http://uat.car.go.th/car-public-api"
const HOST_CAR_PUBLIC_API_V1_TEST = `${HOST_CAR_PUBLIC_API_TEST}/v1`

const HOST_ASSESSMENT_TEST = "http://uat.car.go.th/car/assessment-form"
const HOST_ASSESSMENT_API_TEST = "http://uat.car.go.th/car-main-api/eco-sticker"
const HOST_ASSESSMENT_API_V1_TEST = `${HOST_ASSESSMENT_API_TEST}/api/v1`

///////////////////////////////////
// ---- use env test uat --- //
// const HOST_TYRE_USE = HOST_TYRE_TEST
// const HOST_TYRE_API_USE = HOST_TYRE_API_TEST
// const HOST_TYRE_API_V1_USE = HOST_TYRE_API_V1_TEST
// const HOST_TYRE_PATH_FILE_USE = HOST_TYRE_PATH_FILE_TEST

// const HOST_CAR_PUBLIC_USE = HOST_CAR_PUBLIC_TEST
// const HOST_CAR_PUBLIC_API_USE = HOST_CAR_PUBLIC_API_TEST
// const HOST_CAR_PUBLIC_API_V1_USE = HOST_CAR_PUBLIC_API_V1_TEST

// const HOST_ASSESSMENT_USE = HOST_ASSESSMENT_TEST
// const HOST_ASSESSMENT_API_USE = HOST_ASSESSMENT_API_TEST
// const HOST_ASSESSMENT_API_V1_USE = HOST_ASSESSMENT_API_V1_TEST

///////////////////////////////////
// // ---- use env production --- //
const HOST_TYRE_USE = HOST_TYRE_PROD
const HOST_TYRE_API_USE = HOST_TYRE_API_PROD
const HOST_TYRE_API_V1_USE = HOST_TYRE_API_V1_PROD
const HOST_TYRE_PATH_FILE_USE = HOST_TYRE_PATH_FILE_PROD

const HOST_CAR_PUBLIC_USE = HOST_CAR_PUBLIC_PROD
const HOST_CAR_PUBLIC_API_USE = HOST_CAR_PUBLIC_API_PROD
const HOST_CAR_PUBLIC_API_V1_USE = HOST_CAR_PUBLIC_API_V1_PROD

const HOST_ASSESSMENT_USE = HOST_ASSESSMENT_PROD
const HOST_ASSESSMENT_API_USE = HOST_ASSESSMENT_API_PROD
const HOST_ASSESSMENT_API_V1_USE = HOST_ASSESSMENT_API_V1_PROD

///////////////////////////////////
//-- export var --//
export const HOST_PATH_FILE = HOST_TYRE_PATH_FILE_USE;
export const HOST_CAR = HOST_CAR_PUBLIC_USE;

// TODO: API REGISTER
export const COUNTRY = `${HOST_TYRE_API_V1_USE}/country`;
export const STANDARD = `${HOST_TYRE_API_V1_USE}/standard_qc`;
export const FACTORY_AREA = `${HOST_TYRE_API_V1_USE}/factory_area`;
export const PRODUCT_TYPE = `${HOST_TYRE_API_V1_USE}/product_type`;
export const GET_PROVINCE = `${HOST_TYRE_API_V1_USE}/getProvinces`;
export const GET_DISTRICT = `${HOST_TYRE_API_V1_USE}/getAmphures`;
export const GET_SUB_DISTRICT = `${HOST_TYRE_API_V1_USE}/getDistricts`;
export const REGISTER = `${HOST_TYRE_API_V1_USE}/registerCompany`;
export const EDIT_REGISTER = `${HOST_TYRE_API_V1_USE}/editRegisterCompany`;

// TODO: API UPDATE COMPANY
export const UPLOAD_COMPANY_LOGO_WEBSITE = `${HOST_TYRE_API_V1_USE}/company/logo`;

// CHECK PERMISSION CHANGE PASSWORD
export const CHECK_PERMISSION_CHANGE_PASSWORD = `${HOST_TYRE_API_V1_USE}/company/validate-user-can-update-password`;

// TODO: API EDIT DATA ENTREPRENEUR
export const SEND_EDIT_DATA_ENTREPRENEUR = `${HOST_TYRE_API_V1_USE}/company/addTempCompany`;
export const CHECK_STATUS_TEMP = `${HOST_TYRE_API_V1_USE}/company/checkTempCompany/`;
export const CHANGE_PASSWORD = `${HOST_TYRE_API_V1_USE}/company/changePassword`;

// TODO: API I-Industry
export const GET_DATA_PERSONAL = `${HOST_TYRE_API_V1_USE}/industry/person_detail/`;
export const GET_DATA_JURISTIC = `${HOST_TYRE_API_V1_USE}/industry/juristic_detail/`;

// TODO: API OIE
export const STATIC_FILE = `${HOST_TYRE_API_V1_USE}`;

// TODO: API OIE NEW VERSION
export const OIE_COMPANY_LIST = `${HOST_TYRE_API_V1_USE}/oie/companies`;
export const OIE_COMPANY_FACTORY_DETAIL = `${HOST_TYRE_API_V1_USE}/oie/companie-factories/`;

export const OIE_PENDING = `${HOST_TYRE_API_V1_USE}/oie/pending`;
export const OIE_APPROVE = `${HOST_TYRE_API_V1_USE}/oie/approve`;
export const OIE_REJECT = `${HOST_TYRE_API_V1_USE}/oie/reject`;
export const OIE_EDIT = `${HOST_TYRE_API_V1_USE}/oie/edit`;
export const GET_DATA_COMPANY = `${HOST_TYRE_API_V1_USE}/oie/data/`;
export const GET_REGIS_EDITING_DATA_COMPANY = `${HOST_TYRE_API_V1_USE}/oie/dataRegisterEditing/`;
export const GET_REGIS_EDIT_DATA_COMPANY = `${HOST_TYRE_API_V1_USE}/dataRegisterEditing/`;
export const GET_TEMP_DATA_COMPANY = `${HOST_TYRE_API_V1_USE}/oie/tempData/`;
export const APPROVE_FORM = `${HOST_TYRE_API_V1_USE}/oie/approveRegisterCompany`;
export const REJECT_FORM = `${HOST_TYRE_API_V1_USE}/oie/rejectRegisterCompany`;
export const EDIT_FORM = `${HOST_TYRE_API_V1_USE}/oie/editRegisterCompany`;
export const APPROVE_EDIT_DATA = `${HOST_TYRE_API_V1_USE}/oie/approveTemp`;
export const REJECT_EDIT_DATA = `${HOST_TYRE_API_V1_USE}/oie/rejectTemp`;
export const APPROVE_REGIS_EDITING_FORM = `${HOST_TYRE_API_V1_USE}/oie/appoveCompanyRegisterEditing`;
export const REGIS_EDIT_FORM = `${HOST_TYRE_API_V1_USE}/oie/editRegisterCompany`;
export const REJECT_REGIS_EDITING_FORM = `${HOST_TYRE_API_V1_USE}/oie//rejectCompanyRegisterEditing`;
export const EXPORT_DATA = `${HOST_TYRE_API_V1_USE}/oie/ecoStickerTyre/excel`;
export const OIE_TYRE_REQUEST = `${HOST_TYRE_API_V1_USE}/oie/tyre-request`;
export const COMPANY_TYRE_REQUEST = `${HOST_TYRE_API_V1_USE}/company/tyre-request`;
export const COMPANY_DRAFT_TYRE_REQUEST = `${HOST_TYRE_API_V1_USE}/company/draft-tyre-request`;

//API Export Data OIE
export const OIE_EXPORT_COMPANY_APPROVE = `${HOST_TYRE_API_V1_USE}/oie/companies`; //page :/oie/entrepreneur แบ่งออกเป็น Excel & PDF

// TODO: API LOGIN
export const LOGIN_ADMIN = `${HOST_TYRE_API_V1_USE}/loginAdmin`;
export const LOGIN_USER = `${HOST_TYRE_API_V1_USE}/loginUser`;

// TODO: API INDUSTRY LOGIN
export const GET_TOKEN_COMPANY_INDUSTRY = `${HOST_CAR_PUBLIC_API_V1_USE}/industry-service/eco-access-token`;
export const GET_LIST_COMPANY_INDUSTRY = `${HOST_CAR_PUBLIC_API_V1_USE}/industry-service/company-iIndustry-connection`;
export const GET_ACCESS_TOKEN_AUTO_LOGIN = `${HOST_CAR_PUBLIC_API_V1_USE}/industry-service/auto-login-eco/`;
export const INDUSTRY_LOGIN = `${HOST_CAR_PUBLIC_API_V1_USE}/industry-service/login-eco`;
export const INDUSTRY_COMPANY_REGISTER = `${HOST_CAR_PUBLIC_API_V1_USE}/industry-service/juristic-detail/`;

// Request API
export const TOKEN_COMPANY = () => ({
    headers: {
        Authorization: `Bearer ` + localStorage.getItem("token")
    }
});

// *** ทำไมใช้ HEADER_TOKEN เป็น function :: เปลี่ยนการ เรียกตัวแปล  HEADER_TOKEN ใน route  ให้เป็นการ call function เพื่อแก้ไขปัญหา token ไม่เซ็ทใน header
export const HEADER_TOKEN = () => {
    return {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ` + localStorage.getItem("token")
        }
    };
};

export const HEADER = {
    headers: {
        "content-type": "application/json"
    }
};
export const HEADER_FORMDATA = () => ({
    headers: {
        Authorization: `Bearer ` + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data"
    }
});
export const TOKEN_BLOB = {
    responseType: "arraybuffer"
};

export const GET_REQUEST_DATA_COMPANY = `${HOST_TYRE_API_V1_USE}/requesting/companies`;
export const GET_REQUEST_DATA_BY_COMPANY_ID = `${HOST_TYRE_API_V1_USE}/requesting/companies/`;
export const GET_REQUEST_WAITING_FOR_APPROVAL_DATA_COMPANY = `${HOST_TYRE_API_V1_USE}/requesting/requestApproveStatus/1`;
export const GET_REQUEST_APPROVE_DATA_COMPANY = `${HOST_TYRE_API_V1_USE}/requesting/requestApproveStatus/2`;
export const GET_REQUEST_NOT_ALLOWED_DATA_COMPANY = `${HOST_TYRE_API_V1_USE}/requesting/requestApproveStatus/3`;
export const GET_REQUEST_EDIT_COMPANY = `${HOST_TYRE_API_V1_USE}/requesting/requestApproveStatus/4`;
export const GET_DRAFT_ALL_DATA_COMPANY = `${HOST_TYRE_API_V1_USE}/requesting/companies/draftTyreRequests`;
export const GET_DRAFT_DATA_BY_ID = `${HOST_TYRE_API_V1_USE}/requesting/draftTyreRequests/`;
export const DELETE_DRAFT_DATA_BY_ID = `${HOST_TYRE_API_V1_USE}/requesting/softDeleteDraftTyreRequest/`;
export const SAVE_DRAFT = `${HOST_TYRE_API_V1_USE}/requesting/savingDraft`;
export const SAVE_AS_DRAFT = `${HOST_TYRE_API_V1_USE}/requesting/savingAsDraft`;
export const SAVE_FORMDATA = `${HOST_TYRE_API_V1_USE}/requesting/uploadingFile`;
export const SEND_REQUEST = `${HOST_TYRE_API_V1_USE}/requesting/submitTyreRequest`;
export const SAVE_REQUEST_FILE = `${HOST_TYRE_API_V1_USE}/requesting/uploadingFileSubmitTyreRequest`;
export const GET_REQUEST_DATA_BY_ID = `${HOST_TYRE_API_V1_USE}/requesting/tyreRequest/`;
export const GET_STICKER_DATA_BY_SIZE_ID = `${HOST_TYRE_API_V1_USE}/requesting/tyreSticker/`;
export const GET_DRAFT_STICKER_DATA = `${HOST_TYRE_API_V1_USE}/requesting/draftTyreSticker`;
export const EDIT_REQUEST = `${HOST_TYRE_API_V1_USE}/requesting/editSubmitTyreRequest`;
export const GET_FACTORY_NOT_PAID = `${HOST_TYRE_API_V1_USE}/requesting/factoryRequestingNotPaid`;
export const SAVE_RECEIPT_PER_FACTORY = `${HOST_TYRE_API_V1_USE}/requesting/uploadReceiptPerFactory`;
export const DELETE_REQUEST_DATA_BY_ID = `${HOST_TYRE_API_V1_USE}/requesting/softDeleteTyreRequest/`;

// TODO: API APPROVE
export const Apiurl = `${HOST_TYRE_API_V1_USE}`;
export const Apiurl2 = `${HOST_TYRE_API_V1_USE}`;
export const Preview = `${HOST_TYRE_API_V1_USE}`;
export const Aprroval_waiting = `${HOST_TYRE_API_V1_USE}/approve/getdata_waitapprove`;
export const Approval_auto_approve = `${HOST_TYRE_API_V1_USE}/approve/auto_approve_tisi_oie`;
export const Approval_approve = `${HOST_TYRE_API_V1_USE}/approve/getdata_approve`;
export const Approval_unapprove = `${HOST_TYRE_API_V1_USE}/approve/getdata_unapprove`;
export const Approval_edit = `${HOST_TYRE_API_V1_USE}/approve/getdata_editapprove`;
export const waiting = "/approve/getdata_waitapprove?page=";
export const approve = "/approve/getdata_approve?page=";
export const unapprove = "/approve/getdata_unapprove?page=";
export const edit = "/approve/getdata_editapprove?page=";
export const finance_incomplete = `${HOST_TYRE_API_V1_USE}/finance/get_request`;
export const finance_complete = `${HOST_TYRE_API_V1_USE}/finance/get_approve_request`;
export const finance_approve = "/finance/approve_finance";
export const finance_disapprove = "/finance/un_approve_finance";
export const finance_get_all = `${HOST_TYRE_API_V1_USE}/finance/tyreRequestsGroupByFactories`;
export const approve_receipt = `${HOST_TYRE_API_V1_USE}/finance/approveReceiptPerFactory/`;
export const not_approve_receipt = `${HOST_TYRE_API_V1_USE}/finance/notApproveReceiptPerFactory/`;
export const send_email_by_factory_id = `${HOST_TYRE_API_V1_USE}/finance/sendingInvoiceToMail/`;
export const CHECKINSERTLICENSE = `${HOST_TYRE_API_V1_USE}/finance/check_insert_license_first`;
export const UPLOADFILELICENSE = `${HOST_TYRE_API_V1_USE}/approve/upload_real_license`;
export const RENDERLICENSE = `${HOST_TYRE_API_V1_USE}/approve/render_license`;
export const LOADRENDER = `${HOST_TYRE_API_V1_USE}/approve/getdata_to_renderLicense/`;

export const upload_file_tisi2718_2719 = `${HOST_TYRE_API_V1_USE}/requesting/productRequests/uploadingFileTisi2718-2719/`;
export const upload_file_tisi2721 = `${HOST_TYRE_API_V1_USE}/requesting/productRequests/uploadingFileTisi2721/`;
export const FASTCONTACTMAIL = `${HOST_TYRE_API_V1_USE}/mail/fastContact`;

export const GET_PERSON_BY_USERNAME = `${HOST_TYRE_API_V1_USE}/company/personEmails`;
export const RECOVER_PASSWORD_BY_PERSON_ID = `${HOST_TYRE_API_V1_USE}/company/emailPasswordRecovery`;
export const CHECK_IS_PASSWORD_CAN_RESET = `${HOST_TYRE_API_V1_USE}/company/passwordCheckingCanBeReset`;
export const RESET_PASSWORD = `${HOST_TYRE_API_V1_USE}/company/passwords`;
export const searchSticker = (status, txt) => {
    return `${HOST_TYRE_API_V1_USE}/approve/tyreStickerList?status=${status}&search=${txt}`;
};

export const GET_STATISTICS = `${HOST_TYRE_API_V1_USE}/landingPage/home`;
export const GET_STATISTICS_RESQUEST_STICKER = `${HOST_TYRE_API_V1_USE}/landingPage/report-sticker-year-amount`;
export const GET_VISITORS = `${HOST_TYRE_API_V1_USE}/summary/visitors`;

// new api in dashboard
export const GET_STICKER_AMOUNT_BY_TYPE = `${HOST_TYRE_API_V1_USE}/dashboard/sticker-amount-by-type`;
export const GET_COMPANY_AMOUNT_BY_TYPE = `${HOST_TYRE_API_V1_USE}/dashboard/company-amount-by-type`;

export const CHECK_REGIS_EDITING_STATUS_TEMP = `${HOST_TYRE_API_V1_USE}/doesTempCompanyForRegistrationEditingExist/`;
export const GET_REGIS_EDITING_DATA_COMPANY_ENTREPRENEUR = `${HOST_TYRE_API_V1_USE}/dataRegisterEditing/`;

// Search Trye new module
export const GET_FILTER_OPTION = `${HOST_TYRE_API_V1_USE}/tyre/select_search`;
export const GET_TYRE_LIST = `${HOST_TYRE_API_V1_USE}/tyre/search`;
export const GET_SEARCH_STICKER_DATA_BY_SIZE_ID = `${HOST_TYRE_API_V1_USE}/requesting/tyreSticker/`;

// get assessment form list api
export const MODULE_PREFIX = "tyre"
export const PORTAL_ECO_URL_FRONT_END = HOST_ASSESSMENT_USE;
export const GET_ASSESSMENT_LIST = `${HOST_ASSESSMENT_API_V1_USE}/assessment-form/user/private-list?row=5&search=&sys_module=tyre_company&page=`;


export const getAssessmentList = async (page = 1) => {
    return await Axios({
        url: `${GET_ASSESSMENT_LIST}${page}`,
        ...HEADER_TOKEN()
    });
};

export const getApprovedDataTyre = async ({ row, page, search, tab,type_struct_id,sort_by }) => {
    return await Axios({
        url: `${OIE_TYRE_REQUEST}?row=${row}&page=${page}&search=${search}&tab=${tab}&type_struct_id=${type_struct_id}&sort_by=${sort_by}`,
        method: "GET",
        ...HEADER_TOKEN()
    });
};

// 

export const getApprovedCompanyDataTyre = async ({ row = "10", page = "1", search = "", tab = "approved",type_struct_id = "",sort_by="DESC" }) => {
    return await Axios({
        url: `${COMPANY_TYRE_REQUEST}?row=${row}&page=${page}&search=${search}&tab=${tab}&type_struct_id=${type_struct_id}&sort_by=${sort_by}`,
        method: "GET",
        ...HEADER_TOKEN()
    });
};

export const getApprovedDraftCompanyDataTyre = async ({ row = "10", page = "1", search = "" }) => {
    return await Axios({
        url: `${COMPANY_DRAFT_TYRE_REQUEST}?row=${row}&page=${page}&search=${search}`,
        method: "GET",
        ...HEADER_TOKEN()
    });
};

export const exportApprovedDataTyre = async () => {
    return await Axios({
        url: EXPORT_DATA,
        method: "GET",
        ...HEADER_TOKEN()
    });
};

//
export const exportSummarySticker = async()=>{
    return await Axios({
      url: `${HOST_TYRE_API_V1_USE}/export-document/sticker-report-group-company`,
      responseType: "blob",
      ...HEADER_TOKEN()
    });
  }

export const getCompanyData = async (companies_id) => {
    return await Axios({
        url: `${HOST_TYRE_API_V1_USE}/oie/companie-factories/${companies_id}`,
        method: "GET",
        ...HEADER_TOKEN()
    });
};

export const getCompanyByProductID = async (productId) => {
    return await Axios({
        url: `${HOST_TYRE_API_V1_USE}/company/product-requests/${productId}`,
        method: "GET",
        ...HEADER_TOKEN()
    });
};

export const uploadFileTisi2718_2719 = async ({ formData, productId }) => {
    return await Axios.put(upload_file_tisi2718_2719 + productId, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    });
};

export const uploadFileTisi2721 = async ({ formData, productId }) => {
    return await Axios.put(upload_file_tisi2721 + productId, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    });
};

export const deletedCompanyData = async (id) => {
    return await Axios({
        url: `${HOST_TYRE_API_V1_USE}/requesting/softDeleteDraftTyreRequest/${id}`,
        method: "DELETE",
        ...HEADER_TOKEN()
    });
};

export const deletedOieData = async (id) => {
    return await Axios({
        url: `${HOST_TYRE_API_V1_USE}/requesting/softDeleteTyreRequest/${id}`,
        method: "DELETE",
        ...HEADER_TOKEN()
    });
};

// suggestions 
export const getListSuggestion = async () => {
    let response = await Axios({
        url: `${HOST_TYRE_API_V1_USE}/company/brands-suggestion`,
        method: "GET",
        ...HEADER_TOKEN()
    })
    return response.data;
}
export const updateListSuggestion = async (data) => {
    return await Axios({
        url: `${HOST_TYRE_API_V1_USE}/company/brands-suggestion`,
        method: "PUT",
        data:data,
        ...HEADER_TOKEN()
    })
}

export const createListSuggestion = async (data) => {
    return await Axios({
        url: `${HOST_TYRE_API_V1_USE}/company/brands-suggestion`,
        method: "POST",
        data:data,
        ...HEADER_TOKEN()
    })
}

export const deleteListSuggestion = async (id) => {
    return await Axios({
        url: `${HOST_TYRE_API_V1_USE}/company/brands-suggestion/${id}`,
        method: "DELETE",
        ...HEADER_TOKEN()
    })
}

export const fnGetListOptions = async() =>{
    let response = await Axios({
        url:`${HOST_TYRE_API_V1_USE}/tyre-request/option-data`,
        method:"GET",
        ...HEADER_TOKEN()
    })
    return response.data
}

// PDPA
export const GET_STATUS_PDPA = `${HOST_TYRE_API_V1_USE}/pdpa/consent-status`; // get
export const UPDATE_STATUS_REQUEST_PDPA = `${HOST_TYRE_API_V1_USE}/pdpa/consent`; // post

////////

// const SERVER = "https://tyre.go.th/api"; // ที่มี /api ต่อท้ายเพิ่มเพราะการ set server

// const HOST_CAR_MAIN = "https://car.go.th";

// const API_V1 = "/api/v1";

// const HOST = SERVER;
//  ## มี HOST_PATH_FILE คือโฮสของพาร์ทที่ใช้แสดงไฟล์ใช้เป็นตัวแปลแบบ Global ใหม่แทน STATIC_FILE
// export const HOST_PATH_FILE = `${HOST}${API_V1}`;

// export const HOST_CAR = HOST_CAR_MAIN;

// // TODO: API REGISTER
// export const COUNTRY = `${HOST}${API_V1}/country`;
// export const STANDARD = `${HOST}${API_V1}/standard_qc`;
// export const FACTORY_AREA = `${HOST}${API_V1}/factory_area`;
// export const PRODUCT_TYPE = `${HOST}${API_V1}/product_type`;
// export const GET_PROVINCE = `${HOST}${API_V1}/getProvinces`;
// export const GET_DISTRICT = `${HOST}${API_V1}/getAmphures`;
// export const GET_SUB_DISTRICT = `${HOST}${API_V1}/getDistricts`;
// export const REGISTER = `${HOST}${API_V1}/registerCompany`;
// export const EDIT_REGISTER = `${HOST}${API_V1}/editRegisterCompany`;

// // TODO: API UPDATE COMPANY
// export const UPLOAD_COMPANY_LOGO_WEBSITE = `${HOST}${API_V1}/company/logo`;

// // CHECK PERMISSION CHANGE PASSWORD
// export const CHECK_PERMISSION_CHANGE_PASSWORD = `${HOST}${API_V1}/company/validate-user-can-update-password`;

// // TODO: API EDIT DATA ENTREPRENEUR
// export const SEND_EDIT_DATA_ENTREPRENEUR = `${HOST}${API_V1}/company/addTempCompany`;
// export const CHECK_STATUS_TEMP = `${HOST}${API_V1}/company/checkTempCompany/`;
// export const CHANGE_PASSWORD = `${HOST}${API_V1}/company/changePassword`;

// // TODO: API I-Industry
// export const GET_DATA_PERSONAL = `${HOST}${API_V1}/industry/person_detail/`;
// export const GET_DATA_JURISTIC = `${HOST}${API_V1}/industry/juristic_detail/`;

// // TODO: API OIE
// export const STATIC_FILE = `${HOST}${API_V1}`;

// // TODO: API OIE NEW VERSION
// export const OIE_COMPANY_LIST = `${HOST}${API_V1}/oie/companies`;
// export const OIE_COMPANY_FACTORY_DETAIL = `${HOST}${API_V1}/oie/companie-factories/`;

// export const OIE_PENDING = `${HOST}${API_V1}/oie/pending`;
// export const OIE_APPROVE = `${HOST}${API_V1}/oie/approve`;
// export const OIE_REJECT = `${HOST}${API_V1}/oie/reject`;
// export const OIE_EDIT = `${HOST}${API_V1}/oie/edit`;
// export const GET_DATA_COMPANY = `${HOST}${API_V1}/oie/data/`;
// export const GET_REGIS_EDITING_DATA_COMPANY = `${HOST}${API_V1}/oie/dataRegisterEditing/`;
// export const GET_REGIS_EDIT_DATA_COMPANY = `${HOST}${API_V1}/dataRegisterEditing/`;
// export const GET_TEMP_DATA_COMPANY = `${HOST}${API_V1}/oie/tempData/`;
// export const APPROVE_FORM = `${HOST}${API_V1}/oie/approveRegisterCompany`;
// export const REJECT_FORM = `${HOST}${API_V1}/oie/rejectRegisterCompany`;
// export const EDIT_FORM = `${HOST}${API_V1}/oie/editRegisterCompany`;
// export const APPROVE_EDIT_DATA = `${HOST}${API_V1}/oie/approveTemp`;
// export const REJECT_EDIT_DATA = `${HOST}${API_V1}/oie/rejectTemp`;
// export const APPROVE_REGIS_EDITING_FORM = `${HOST}${API_V1}/oie/appoveCompanyRegisterEditing`;
// export const REGIS_EDIT_FORM = `${HOST}${API_V1}/oie/editRegisterCompany`;
// export const REJECT_REGIS_EDITING_FORM = `${HOST}${API_V1}/oie//rejectCompanyRegisterEditing`;
// export const EXPORT_DATA = `${HOST}${API_V1}/oie/ecoStickerTyre/excel`;
// export const OIE_TYRE_REQUEST = `${HOST}${API_V1}/oie/tyre-request`;
// export const COMPANY_TYRE_REQUEST = `${HOST}${API_V1}/company/tyre-request`;
// export const COMPANY_DRAFT_TYRE_REQUEST = `${HOST}${API_V1}/company/draft-tyre-request`;

// //API Export Data OIE
// export const OIE_EXPORT_COMPANY_APPROVE = `${HOST}${API_V1}/oie/companies`; //page :/oie/entrepreneur แบ่งออกเป็น Excel & PDF

// // TODO: API LOGIN
// export const LOGIN_ADMIN = `${HOST}${API_V1}/loginAdmin`;
// export const LOGIN_USER = `${HOST}${API_V1}/loginUser`;

// // TODO: API INDUSTRY LOGIN
// export const GET_TOKEN_COMPANY_INDUSTRY = `${HOST_CAR}${API_V1}/industry-service/eco-access-token`;
// export const GET_LIST_COMPANY_INDUSTRY = `${HOST_CAR}${API_V1}/industry-service/company-iIndustry-connection`;
// export const GET_ACCESS_TOKEN_AUTO_LOGIN = `${HOST_CAR}${API_V1}/industry-service/auto-login-eco/`;
// export const INDUSTRY_LOGIN = `${HOST_CAR}${API_V1}/industry-service/login-eco`;
// export const INDUSTRY_COMPANY_REGISTER = `${HOST_CAR}${API_V1}/industry-service/juristic-detail/`;

// // Request API
// export const TOKEN_COMPANY = () => ({
//     headers: {
//         Authorization: `Bearer ` + localStorage.getItem("token")
//     }
// });

// // *** ทำไมใช้ HEADER_TOKEN เป็น function :: เปลี่ยนการ เรียกตัวแปล  HEADER_TOKEN ใน route  ให้เป็นการ call function เพื่อแก้ไขปัญหา token ไม่เซ็ทใน header
// export const HEADER_TOKEN = () => {
//     return {
//         headers: {
//             "content-type": "application/json",
//             Authorization: `Bearer ` + localStorage.getItem("token")
//         }
//     };
// };

// export const HEADER = {
//     headers: {
//         "content-type": "application/json"
//     }
// };
// export const HEADER_FORMDATA = () => ({
//     headers: {
//         Authorization: `Bearer ` + localStorage.getItem("token"),
//         "Content-Type": "multipart/form-data"
//     }
// });
// export const TOKEN_BLOB = {
//     responseType: "arraybuffer"
// };
// export const GET_REQUEST_DATA_COMPANY = `${HOST}${API_V1}/requesting/companies`;
// export const GET_REQUEST_DATA_BY_COMPANY_ID = `${HOST}${API_V1}/requesting/companies/`;
// export const GET_REQUEST_WAITING_FOR_APPROVAL_DATA_COMPANY = `${HOST}${API_V1}/requesting/requestApproveStatus/1`;
// export const GET_REQUEST_APPROVE_DATA_COMPANY = `${HOST}${API_V1}/requesting/requestApproveStatus/2`;
// export const GET_REQUEST_NOT_ALLOWED_DATA_COMPANY = `${HOST}${API_V1}/requesting/requestApproveStatus/3`;
// export const GET_REQUEST_EDIT_COMPANY = `${HOST}${API_V1}/requesting/requestApproveStatus/4`;
// export const GET_DRAFT_ALL_DATA_COMPANY = `${HOST}${API_V1}/requesting/companies/draftTyreRequests`;
// export const GET_DRAFT_DATA_BY_ID = `${HOST}${API_V1}/requesting/draftTyreRequests/`;
// export const DELETE_DRAFT_DATA_BY_ID = `${HOST}${API_V1}/requesting/softDeleteDraftTyreRequest/`;
// export const SAVE_DRAFT = `${HOST}${API_V1}/requesting/savingDraft`;
// export const SAVE_AS_DRAFT = `${HOST}${API_V1}/requesting/savingAsDraft`;
// export const SAVE_FORMDATA = `${HOST}${API_V1}/requesting/uploadingFile`;
// export const SEND_REQUEST = `${HOST}${API_V1}/requesting/submitTyreRequest`;
// export const SAVE_REQUEST_FILE = `${HOST}${API_V1}/requesting/uploadingFileSubmitTyreRequest`;
// export const GET_REQUEST_DATA_BY_ID = `${HOST}${API_V1}/requesting/tyreRequest/`;
// export const GET_STICKER_DATA_BY_SIZE_ID = `${HOST}${API_V1}/requesting/tyreSticker/`;
// export const GET_DRAFT_STICKER_DATA = `${HOST}${API_V1}/requesting/draftTyreSticker`;
// export const EDIT_REQUEST = `${HOST}${API_V1}/requesting/editSubmitTyreRequest`;
// export const GET_FACTORY_NOT_PAID = `${HOST}${API_V1}/requesting/factoryRequestingNotPaid`;
// export const SAVE_RECEIPT_PER_FACTORY = `${HOST}${API_V1}/requesting/uploadReceiptPerFactory`;
// export const DELETE_REQUEST_DATA_BY_ID = `${HOST}${API_V1}/requesting/softDeleteTyreRequest/`;

// // TODO: API APPROVE
// export const Apiurl = `${HOST}${API_V1}`;
// export const Apiurl2 = `${HOST}${API_V1}`;
// export const Preview = `${HOST}${API_V1}`;
// export const Aprroval_waiting = `${HOST}${API_V1}/approve/getdata_waitapprove`;
// export const Approval_auto_approve = `${HOST}${API_V1}/approve/auto_approve_tisi_oie`;
// export const Approval_approve = `${HOST}${API_V1}/approve/getdata_approve`;
// export const Approval_unapprove = `${HOST}${API_V1}/approve/getdata_unapprove`;
// export const Approval_edit = `${HOST}${API_V1}/approve/getdata_editapprove`;
// export const waiting = "/approve/getdata_waitapprove?page=";
// export const approve = "/approve/getdata_approve?page=";
// export const unapprove = "/approve/getdata_unapprove?page=";
// export const edit = "/approve/getdata_editapprove?page=";
// export const finance_incomplete = `${HOST}${API_V1}/finance/get_request`;
// export const finance_complete = `${HOST}${API_V1}/finance/get_approve_request`;
// export const finance_approve = "/finance/approve_finance";
// export const finance_disapprove = "/finance/un_approve_finance";
// export const finance_get_all = `${HOST}${API_V1}/finance/tyreRequestsGroupByFactories`;
// export const approve_receipt = `${HOST}${API_V1}/finance/approveReceiptPerFactory/`;
// export const not_approve_receipt = `${HOST}${API_V1}/finance/notApproveReceiptPerFactory/`;
// export const send_email_by_factory_id = `${HOST}${API_V1}/finance/sendingInvoiceToMail/`;
// export const CHECKINSERTLICENSE = `${HOST}${API_V1}/finance/check_insert_license_first`;
// export const UPLOADFILELICENSE = `${HOST}${API_V1}/approve/upload_real_license`;
// export const RENDERLICENSE = `${HOST}${API_V1}/approve/render_license`;
// export const LOADRENDER = `${HOST}${API_V1}/approve/getdata_to_renderLicense/`;

// export const upload_file_tisi2718_2719 = `${HOST}${API_V1}/requesting/productRequests/uploadingFileTisi2718-2719/`;
// export const upload_file_tisi2721 = `${HOST}${API_V1}/requesting/productRequests/uploadingFileTisi2721/`;
// export const FASTCONTACTMAIL = `${HOST}${API_V1}/mail/fastContact`;

// export const GET_PERSON_BY_USERNAME = `${HOST}${API_V1}/company/personEmails`;
// export const RECOVER_PASSWORD_BY_PERSON_ID = `${HOST}${API_V1}/company/emailPasswordRecovery`;
// export const CHECK_IS_PASSWORD_CAN_RESET = `${HOST}${API_V1}/company/passwordCheckingCanBeReset`;
// export const RESET_PASSWORD = `${HOST}${API_V1}/company/passwords`;
// export const searchSticker = (status, txt) => {
//     return `${HOST}${API_V1}/approve/tyreStickerList?status=${status}&search=${txt}`;
// };

// export const GET_STATISTICS = `${HOST}${API_V1}/landingPage/home`;
// export const GET_STATISTICS_RESQUEST_STICKER = `${HOST}${API_V1}/landingPage/report-sticker-year-amount`;
// export const GET_VISITORS = `${HOST}${API_V1}/summary/visitors`;

// // new api in dashboard
// export const GET_STICKER_AMOUNT_BY_TYPE = `${HOST}${API_V1}/dashboard/sticker-amount-by-type`;
// export const GET_COMPANY_AMOUNT_BY_TYPE = `${HOST}${API_V1}/dashboard/company-amount-by-type`;



// export const CHECK_REGIS_EDITING_STATUS_TEMP = `${HOST}${API_V1}/doesTempCompanyForRegistrationEditingExist/`;
// export const GET_REGIS_EDITING_DATA_COMPANY_ENTREPRENEUR = `${HOST}${API_V1}/dataRegisterEditing/`;
// // /finance/sendingInvoiceToMail/

// // Search Trye new module
// export const GET_FILTER_OPTION = `${HOST}${API_V1}/tyre/select_search`;
// export const GET_TYRE_LIST = `${HOST}${API_V1}/tyre/search`;
// export const GET_SEARCH_STICKER_DATA_BY_SIZE_ID = `${HOST}${API_V1}/requesting/tyreSticker/`;
// // export const SEARCH_HOST_PATH_FILE = `${HOST}${API_V1}/`;
// // export const SEARCH_HOST_PATH_FILE = `http://172.168.223.5:1323/api/v1`;

// // get assessment form list api
// export const MODULE_PREFIX = "tyre"
// export const PORTAL_ECO_URL_FRONT_END = `${HOST_CAR}/assessment-form/`;
// const PORTAL_ECO_STICKER_API = `${HOST_CAR}/eco-sticker/api/v1`;
// export const GET_ASSESSMENT_LIST = `${PORTAL_ECO_STICKER_API}/assessment-form/user/private-list?row=5&search=&sys_module=tyre_company&page=`;

// export const getAssessmentList = async (page = 1) => {
//     return await Axios({
//         url: `${GET_ASSESSMENT_LIST}${page}`,
//         ...HEADER_TOKEN()
//     });
// };

// export const getApprovedDataTyre = async ({ row, page, search, tab }) => {
//     return await Axios({
//         url: `${OIE_TYRE_REQUEST}?row=${row}&page=${page}&search=${search}&tab=${tab}`,
//         method: "GET",
//         ...HEADER_TOKEN()
//     });
// };

// export const getApprovedCompanyDataTyre = async ({ row = "10", page = "1", search = "", tab = "approved" }) => {
//     return await Axios({
//         url: `${COMPANY_TYRE_REQUEST}?row=${row}&page=${page}&search=${search}&tab=${tab}`,
//         method: "GET",
//         ...HEADER_TOKEN()
//     });
// };

// export const getApprovedDraftCompanyDataTyre = async ({ row = "10", page = "1", search = "" }) => {
//     return await Axios({
//         url: `${COMPANY_DRAFT_TYRE_REQUEST}?row=${row}&page=${page}&search=${search}`,
//         method: "GET",
//         ...HEADER_TOKEN()
//     });
// };

// export const exportApprovedDataTyre = async () => {
//     return await Axios({
//         url: EXPORT_DATA,
//         method: "GET",
//         ...HEADER_TOKEN()
//     });
// };

// 
// export const exportSummarySticker = async()=>{
//     return await Axios({
//       url: `${HOST}${API_V1}/export-document/sticker-report-group-company`,
//       responseType: "blob",
//       ...HEADER_TOKEN()
//     });
//   }

// export const getCompanyData = async (companies_id) => {
//     return await Axios({
//         url: `${HOST}${API_V1}/oie/companie-factories/${companies_id}`,
//         method: "GET",
//         ...HEADER_TOKEN()
//     });
// };

// export const getCompanyByProductID = async (productId) => {
//     return await Axios({
//         url: `${HOST}${API_V1}/company/product-requests/${productId}`,
//         method: "GET",
//         ...HEADER_TOKEN()
//     });
// };

// export const uploadFileTisi2718_2719 = async ({ formData, productId }) => {
//     return await Axios.put(upload_file_tisi2718_2719 + productId, formData, {
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
//     });
// };

// export const uploadFileTisi2721 = async ({ formData, productId }) => {
//     return await Axios.put(upload_file_tisi2721 + productId, formData, {
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
//     });
// };

// export const deletedCompanyData = async (id) => {
//     return await Axios({
//         url: `${HOST}${API_V1}/requesting/softDeleteDraftTyreRequest/${id}`,
//         method: "DELETE",
//         ...HEADER_TOKEN()
//     });
// };

// export const deletedOieData = async (id) => {
//     return await Axios({
//         url: `${HOST}${API_V1}/requesting/softDeleteTyreRequest/${id}`,
//         method: "DELETE",
//         ...HEADER_TOKEN()
//     });
// };

// // suggestions 
// export const getListSuggestion = async () => {
//     let response = await Axios({
//         url: `${HOST}${API_V1}/company/brands-suggestion`,
//         method: "GET",
//         ...HEADER_TOKEN()
//     })
//     return response.data;
// }
// export const updateListSuggestion = async (data) => {
//     return await Axios({
//         url: `${HOST}${API_V1}/company/brands-suggestion`,
//         method: "PUT",
//         data:data,
//         ...HEADER_TOKEN()
//     })
// }

// export const createListSuggestion = async (data) => {
//     return await Axios({
//         url: `${HOST}${API_V1}/company/brands-suggestion`,
//         method: "POST",
//         data:data,
//         ...HEADER_TOKEN()
//     })
// }

// export const deleteListSuggestion = async (id) => {
//     return await Axios({
//         url: `${HOST}${API_V1}/company/brands-suggestion/${id}`,
//         method: "DELETE",
//         ...HEADER_TOKEN()
//     })
// }

// // PDPA
// export const GET_STATUS_PDPA = `${HOST}${API_V1}/pdpa/consent-status`; // get
// export const UPDATE_STATUS_REQUEST_PDPA = `${HOST}${API_V1}/pdpa/consent`; // post
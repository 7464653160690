import React, { useEffect, useMemo, useState } from "react";
import Headers from "../header/requestHeader";
import { Button, Container, Divider, Image, Menu, Popup, Dropdown, Icon, Dimmer, Loader } from "semantic-ui-react";
import MainTable from "../MainTable";
import { deletedCompanyData, fnGetListOptions, getApprovedCompanyDataTyre, getApprovedDraftCompanyDataTyre } from "../router";
import { useLocation, useHistory, Link } from "react-router-dom";
import OiePagination from "../OiePagination";
import style from "./style.module.css";
import { COLORS, PopupTrigger } from "../approval/list/tisi_oie/HomeV2Func";
import moment from "moment";
import * as Types from "./type";
import ModalEditData from "../company/ModalEditData";
import AssesmentModal from "../AssesmentModal";
import styles from "./RequestIndexNew.module.scss";
import Swal from "sweetalert2";

const sort_mock_table = [
	{
		id: "52e35d26-be9e-4bda-9e3d-74fead6dc227",
		text: "น้อยไปมาก",
		value: "ASC",
	},
	{
		id: "c8f8dcc0-4d40-4d20-8884-bf0a637ddce5",
		text: "มากไปน้อย",
		value: "DESC",
	},
];
const sort_mock_engine = [
	{
		id: 1,
		text: "เรเดียล",
		value: "เรเดียล",
	},
	{
		id: 2,
		text: "ผ้าใบเฉียง",
		value: "ผ้าใบเฉียง",
	},
	{
		id: 3,
		text: "ผ้าใบเฉียงแบบเบลท์",
		value: "ผ้าใบเฉียงแบบเบลท์",
	},
	{
		id: 4,
		text: "เรเดียลแบบรันแฟลต",
		value: "เรเดียลแบบรันแฟลต",
	},
];

const RequestIndexNewest = () => {
	const [data, setData] = useState({});
	const { search } = useLocation();
	const [open_modal, setOpenModal] = useState(false);
	const searchParams = useMemo(() => new URLSearchParams(search), [search]);
	const [rows_per_page, setRowsPerPage] = useState(searchParams.get("row") || 10);
	const [page, setPage] = useState(searchParams.get("page") || 1);
	const [open_popup, setOpenPopup] = useState(false);
	const [active_tab, setActiveTab] = useState(searchParams.get("tab") || "database");
	const [sort_by, setSortBy] = useState(searchParams.get("sort_by") || "DESC");
	const [type_structs, setTypeStructs] = useState(Number(searchParams.get("type_struct_id")) || "");
	const [options_type_structs, setOptionsTypeStructs] = useState([]);
	const [selected_product_id, setSelectDataProductById] = useState();
	const [key, setKey] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const history = useHistory();

	useEffect(() => {
		fnGetCompanyData();
	}, [page, rows_per_page, active_tab, sort_by, type_structs]);

	function transformResponse(data) {
		return data.map((item) => ({
			key: item.id,
			text: item.name,
			value: item.id,
		}));
	}

	const fnGetCompanyData = async () => {
		searchParams.set("page", page);
		searchParams.set("row", rows_per_page);
		searchParams.set("tab", active_tab);
		searchParams.set("sort_by", sort_by);
		searchParams.set("type_struct_id", type_structs);
		history.replace({ search: searchParams.toString() });
		setData([]);
		setIsLoading(true);
		try {
			if (active_tab === "database") {
				const result = await getApprovedCompanyDataTyre({
					tab: "approved",
					page: page,
					row: rows_per_page,
					sort_by: sort_by,
					type_struct_id: type_structs,
				});
				const res_options = await fnGetListOptions();
				let options_type = transformResponse(res_options?.value?.type_structs);
				setOptionsTypeStructs(options_type);
				setData(result.data.data);
			} else {
				const result_draft = await getApprovedDraftCompanyDataTyre({
					page: page,
					row: rows_per_page,
				});

				setData(result_draft.data.data);
			}
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};

	const fnHandleChangePage = (newPage) => {
		setPage(newPage);
	};

	const fnHandleChangeRowsPerPage = (value) => {
		setPage(1);
		setRowsPerPage(value);
	};

	const fnShowListData = (request, keyName, totals = 3) => {
		return request.slice(0, totals)?.map((data, index) => (
			<div
				key={index}
				className={styles.productRow}
				style={{ color: COLORS[index], padding: 5, textAlign: "left", cursor: active_tab === "database" ? "pointer" : "default" }}
				onClick={() => {
					if (active_tab === "database") {
						setSelectDataProductById(data.product_requests_id);
						setKey(index);
						setOpenModal(true);
					}
				}}
			>
				<span>{data[keyName]}</span>
				{active_tab !== "draft" && (
					<Button
						size="tiny"
						className={styles.button}
						color="green"
					>
						เอกสาร มอก.
					</Button>
				)}
			</div>
		));
	};

	const fnProductColor = (requests) => {
		return requests?.map((product, index) => {
			return {
				product_id: product.product_requests_id,
				color: COLORS[index],
			};
		});
	};

	const fnGroupProductList = ({ request, keyName, totals = 3, productColor, type = "main" }) => {
		return request?.map((data, index) => {
			if (type === "main") {
				if (index >= totals) return null;
			} else {
				if (index < totals) return null;
			}

			const data_with_color = productColor?.map((item) => {
				if (item.product_id === data.product_requests_id) {
					return (
						<div style={{ display: "flex", textAlign: "left", alignItems: "center", padding: 5 }}>
							<div style={{ color: item.color }}>{data[keyName] ? data[keyName] : "-"}</div>
						</div>
					);
				}
			});
			return data_with_color;
		});
	};

	const ContentPopup = ({ moreData, title = "" }) => {
		const content_result = moreData.map((data) => {
			return data;
		});
		content_result.unshift(<div>{title}</div>);
		return <div>{content_result ? content_result : "-"}</div>;
	};

	const columns = [
		{
			label: "ลำดับ",
			styleHead: {
				width: 50,
				minWidth: 50,
				whiteSpace: "nowarp",
			},
			style: {
				textAlign: "center",
			},
			format: ({ index }) => rows_per_page * (page - 1) + (index + 1),
		},
		{
			label: "Reference Code",
			styleHead: {
				width: 80,
				minWidth: 80,
				whiteSpace: "nowrap",
			},
			style: {
				textAlign: "center",
				whiteSpace: "nowrap",
			},
			showOnTab: ["database"],
			format: ({ data }) => {
				return (
					<Link
						to={{
							pathname: "/company/preview-request",
						}}
						onClick={() => {
							localStorage.setItem("requestId", data.tyre_requests_id);
						}}
					>
						{data.ref_code}
					</Link>
				);
			},
		},
		{
			label: "ชื่อฉบับร่าง",
			styleHead: {
				width: 150,
				minWidth: 150,
				whiteSpace: "nowrap",
			},
			style: {
				textAlign: "center",
				whiteSpace: "nowrap",
			},
			showOnTab: ["draft"],
			format: ({ data }) => {
				return (
					<Link
						to={{
							pathname: "/company/request-license",
						}}
						onClick={() => {
							localStorage.setItem("draftId", data.tyre_requests_id);
						}}
					>
						{data.tyre_requests_name}
					</Link>
				);
			},
		},
		{
			label: "โรงงาน",
			styleHead: {
				width: 300,
				minWidth: 300,
				whiteSpace: "nowrap",
			},
			alignHead: "center",
			format: ({ data }) => {
				return (
					<div style={{ display: "flex", flexDirection: "column" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 8, textAlign: "left" }}>{data.factories_name}</div>
							<div className={style.typeFactoryCard}>{data.type_factories}</div>
						</div>
						{data.factories_address && (
							<div className={style.cardAddress}>
								<div style={{ fontSize: "0.8rem" }}>ที่อยู่ :</div>
								<div style={{ textAlign: "start", fontSize: "0.8rem" }}>{data.factories_address}</div>
							</div>
						)}
					</div>
				);
			},
		},
		{
			label: "ผลิตภัณท์",
			styleHead: {
				width: 300,
				minWidth: 300,
				whiteSpace: "nowrap",
			},
			style: {
				padding: 0,
			},
			format: ({ data }) => {
				const keys = "product_name";
				const request = active_tab === "database" ? data.product_requests : data.draft_product_requests;
				const product_name = fnShowListData(request, keys);

				const see_more = request.slice(3)?.map((data, index) => (
					<div
						key={index + 3}
						style={{ color: COLORS[index + 3], padding: 5, cursor: active_tab === "database" ? "pointer" : "default" }}
						onClick={() => {
							if (active_tab === "database") {
								setSelectDataProductById(data.product_requests_id);
								setKey(index + 3);
								setOpenModal(true);
							}
						}}
						className={styles.productRow}
					>
						<span>{data.product_name}</span>
						{active_tab !== "draft" && (
							<Button
								size="tiny"
								className={styles.button}
								color="green"
							>
								เอกสาร มอก.
							</Button>
						)}
					</div>
				));

				const content_result = [<div key="header">รายการผลิตภัณท์</div>, ...see_more];

				if (request.length > 3) {
					product_name.push(
						<Popup
							className={style.customPopup}
							on="click"
							position="bottom left"
							trigger={
								<div style={{ position: "relative" }}>
									<PopupTrigger />
								</div>
							}
						>
							<div style={{ minWidth: "250px" }}>
								<div>{content_result}</div>
							</div>
						</Popup>
					);
				}

				return (
					<div
						style={{
							width: "100%",
						}}
					>
						{product_name}
					</div>
				);
			},
		},
		{
			label: "ยี่ห้อ",
			styleHead: {
				width: 200,
				minWidth: 200,
				whiteSpace: "nowrap",
			},
			format: ({ data }) => {
				const keys = "brand_name";
				const request = active_tab === "database" ? data.brand_requests : data.draft_brand_requests;
				const product_color = fnProductColor(active_tab === "database" ? data.product_requests : data.draft_product_requests);

				const brand_name = fnGroupProductList({ request: request, keyName: keys, productColor: product_color });
				const seemore_brand_name = fnGroupProductList({
					request: request,
					keyName: keys,
					productColor: product_color,
					type: "info",
				});

				const data_length = active_tab === "database" ? data?.product_requests?.length : data.draft_product_requests?.length;

				if (data_length > 3) {
					brand_name.push(
						<Popup
							className={style.customPopup}
							on="click"
							position="bottom left"
							trigger={
								<div style={{ position: "relative" }}>
									<PopupTrigger />
								</div>
							}
						>
							<div style={{ minWidth: "250px" }}>
								<ContentPopup
									moreData={seemore_brand_name}
									title="รายการยี่ห้อ"
								/>
							</div>
						</Popup>
					);
				}

				return brand_name;
			},
		},
		{
			label: "รุ่นยาง",
			styleHead: {
				width: 200,
				minWidth: 200,
				whiteSpace: "nowrap",
			},
			alignHead: "center",
			format: ({ data }) => {
				const keys = "gen_name";
				const request = active_tab === "database" ? data.gen_requests : data.draft_gen_requests;
				const product_color = fnProductColor(active_tab === "database" ? data.product_requests : data.draft_product_requests);

				const gen_requests = fnGroupProductList({ request: request, keyName: keys, productColor: product_color });
				const seemore_gen_requests = fnGroupProductList({
					request: request,
					keyName: keys,
					productColor: product_color,
					type: "info",
				});

				const data_length = active_tab === "database" ? data.product_requests?.length : data.draft_product_requests?.length;

				if (data_length > 3) {
					gen_requests.push(
						<Popup
							className={style.customPopup}
							on="click"
							position="bottom left"
							trigger={
								<div style={{ position: "relative" }}>
									<PopupTrigger />
								</div>
							}
						>
							<div style={{ minWidth: "250px" }}>
								<ContentPopup
									moreData={seemore_gen_requests}
									title="รายการยี่ห้อ"
								/>
							</div>
						</Popup>
					);
				}

				return gen_requests;
			},
		},
		{
			label: "ขนาดยาง",
			styleHead: {
				width: 250,
				minWidth: 250,
				whiteSpace: "nowrap",
			},
			style: {
				minWidth: "fit-content",
				padding: 10,
			},
			format: ({ data }) => {
				const product_data = active_tab === "database" ? data.product_requests : data.draft_product_requests;
				const product_color = fnProductColor(product_data);
				const size_requests_count = {};
				const size_data = active_tab === "database" ? data.size_requests : data.draft_size_requests;
				let size_text;
				if (size_data !== null) {
					for (const size of size_data) {
						const product_requests_id = size.product_requests_id;
						if (size_requests_count.hasOwnProperty(product_requests_id)) {
							size_requests_count[product_requests_id]++;
						} else {
							size_requests_count[product_requests_id] = 1;
						}
					}

					const grouped_data = {};
					for (const item of size_data) {
						const product_requests_id = item.product_requests_id;

						if (!grouped_data[product_requests_id]) {
							grouped_data[product_requests_id] = [];
						}

						grouped_data[product_requests_id].push(item);
					}

					const value_group_data = Object.values(grouped_data);

					const result = {};
					for (const size of size_data) {
						if (!(size.product_requests_id in result)) {
							result[size.product_requests_id] = {
								product_requests_id: size.product_requests_id,
								size_text: size.size_text,
							};
						}
					}

					const first_of_id = Object.values(result);
					const size_requests = first_of_id.slice(0, 3)?.map((size) => {
						const relevantColors = product_color?.filter((item) => item.product_id === size.product_requests_id);

						const product_name = product_data
							?.filter((element) => element.product_requests_id === size.product_requests_id)
							?.map((element) => <div key={element.product_requests_id}>{element.product_name}</div>);

						const size_with_color = relevantColors?.map((item_with_color, key) => (
							<div
								key={key}
								className={style.containerSize}
							>
								<div style={{ color: item_with_color.color, paddingRight: 10 }}>{size.size_text}</div>

								{size_requests_count[item_with_color.product_id] >= 2 && (
									<div>
										<Popup
											className={style.popUpSize}
											on="click"
											position="right center"
											offset={"40%p,0"}
											trigger={
												<div style={{ position: "relative" }}>
													<Button size="mini">...</Button>
												</div>
											}
										>
											<div style={{ minWidth: "200px" }}>ผลิตภัณฑ์</div>
											<div
												className={style.headerSizeProductName}
												style={{
													backgroundColor: item_with_color.color,
												}}
											>
												{product_name}
											</div>
											<div style={{ paddingTop: 3 }}>รายการขนาดยางเพิ่มเติม</div>
											<div
												style={{
													color: item_with_color.color,
													display: "flex",
													flexDirection: "column",
													padding: 5,
												}}
											>
												{value_group_data?.map((element) => {
													const response = element
														.filter(
															(value) =>
																value.product_requests_id === size.product_requests_id &&
																value !== element[0]
														)
														?.map((value, index) => (
															<div
																style={{ width: "100%" }}
																key={index}
															>
																{value.size_text}
															</div>
														));
													return response;
												})}
											</div>
										</Popup>
									</div>
								)}
							</div>
						));
						return size_with_color;
					});

					const seemore_size_requests = first_of_id.slice(3)?.map((size, index) => {
						const relevantColors = product_color?.filter((item) => item.product_id === size.product_requests_id);
						const product_name = product_data
							.filter((element) => element.product_requests_id === size.product_requests_id)
							?.map((element) => <div key={element.product_requests_id}>{element.product_name}</div>);

						const size_with_color = relevantColors?.map((item_with_color, key) => (
							<div
								key={key}
								className={style.containerSize}
							>
								<div style={{ color: item_with_color.color, paddingRight: 10 }}>{size.size_text}</div>

								{size_requests_count[item_with_color.product_id] >= 3 && (
									<div>
										<Popup
											className={style.popUpSize}
											on="click"
											position="right center"
											offset={"40%p,0"}
											onOpen={() => setOpenPopup(true)}
											onClose={() => setOpenPopup(false)}
											trigger={
												<div style={{ position: "relative" }}>
													<Button size="mini">...</Button>
												</div>
											}
										>
											<div style={{ minWidth: "200px" }}>ผลิตภัณฑ์</div>
											<div
												className={style.headerSizeProductName}
												style={{
													backgroundColor: item_with_color.color,
												}}
											>
												{product_name}
											</div>
											<div style={{ paddingTop: 3 }}>รายการขนาดยางเพิ่มเติม</div>
											{value_group_data?.map((element, index) => (
												<div key={index}>{element.size_text}</div>
											))}
										</Popup>
									</div>
								)}
							</div>
						));

						return size_with_color;
					});

					const ContentPopup = () => {
						const content_result = seemore_size_requests?.map((item) => {
							return item;
						});
						return <div>{content_result}</div>;
					};

					if (product_data.length > 3) {
						size_requests.push(
							<Popup
								eventsEnabled={true}
								className={style.customPopup}
								on="click"
								open={open_popup}
								position="bottom left"
								onClose={() => setOpenPopup(false)}
								onOpen={() => setOpenPopup(true)}
								trigger={
									<div style={{ position: "relative" }}>
										<PopupTrigger />
									</div>
								}
							>
								<div style={{ minWidth: "200px" }}>รายการขนาดยาง</div>
								<div>
									<ContentPopup />
								</div>
							</Popup>
						);
					}
					size_text = size_requests;
				}

				return <div style={{ paddingRight: 5, paddingLeft: 5 }}>{size_text}</div>;
			},
		},
		{
			id: "approve_oie_tisi_text",
			label: "สถานะ",
			showOnTab: ["database"],
			styleHead: {
				width: 100,
				minWidth: 100,
				whiteSpace: "nowrap",
			},
			style: {
				textAlign: "center",
			},
		},
		{
			label: "วันที่ยื่น",
			styleHead: {
				width: 150,
				minWidth: 100,
				whiteSpace: "nowrap",
			},
			showOnTab: ["database"],
			style: {
				textAlign: "center",
			},
			format: ({ data }) => {
				const date_format = moment(data.created_at).add(543, "years").format("DD/MM/YYYY");
				return <div>{date_format}</div>;
			},
		},
		{
			label: "วันที่สร้าง",
			styleHead: {
				width: 150,
				minWidth: 100,
				whiteSpace: "nowrap",
			},
			showOnTab: ["draft"],
			style: {
				textAlign: "center",
			},
			format: ({ data }) => {
				const date_format = moment(data.created_at).add(543, "years").format("DD/MM/YYYY");
				return <div>{date_format}</div>;
			},
		},
		{
			label: "วันที่แก้ไขล่าสุด",
			styleHead: {
				width: 150,
				minWidth: 100,
				whiteSpace: "nowrap",
			},
			showOnTab: ["draft"],
			style: {
				textAlign: "center",
			},
			format: ({ data }) => {
				const date_format = moment(data.updated_at).add(543, "years").format("DD/MM/YYYY");
				return <div>{date_format}</div>;
			},
		},
		{
			label: "การดำเนินการ",
			styleHead: {
				width: 150,
				minWidth: 100,
				whiteSpace: "nowrap",
			},
			showOnTab: ["draft"],
			style: {
				textAlign: "center",
			},
			format: ({ data }) => {
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<Button
							size="tiny"
							className={styles.button}
							color="yellow"
							onClick={() => {
								localStorage.setItem("draftId", data.tyre_requests_id);
								history.push("/company/request-license");
							}}
						>
							แก้ไข
						</Button>
						<Button
							size="tiny"
							className={styles.button}
							color="red"
							onClick={() => {
								Swal.fire({
									title: "ยืนยันการลบ",
									showConfirmButton: true,
									showCancelButton: true,
									confirmButtonText: "ยืนยัน",
									cancelButtonText: "ยกเลิก",
								}).then((value) => {
									if (value.isConfirmed) {
										console.log(data);
										fnDeletedData(data.tyre_requests_id);
									}
								});
							}}
						>
							ลบข้อมูล
						</Button>
					</div>
				);
			},
		},
	];

	const fnDeletedData = async (id) => {
		try {
			const result = await deletedCompanyData(id);
			Swal.fire({
				icon: "success",
				title: "ดำเนินการเสร็จสิ้น",
				showConfirmButton: false,
				timer: 1000,
			});
			fnGetCompanyData();
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "ผิดพลาด",
				showConfirmButton: false,
				timer: 1000,
				titleText: error.message,
			});
		}
	};

	return (
		<div>
			<Dimmer
				active={isLoading}
				inverted
			>
				<Loader size="large">กำลังโหลดข้อมูล</Loader>
			</Dimmer>
			<Headers />
			<AssesmentModal />
			<div style={{ margin: 10 }}>
				<Container fluid>
					<Divider />
					<Menu
						attached="top"
						icon="labeled"
						tabular
						color="violet"
						size="small"
					>
						<Menu.Item
							name="ฐานข้อมูล Eco Sticker"
							icon="database"
							onClick={() => {
								setActiveTab("database");
								setPage(1);
							}}
							active={active_tab === "database"}
						>
							<Image
								src={`${process.env.PUBLIC_URL}/logo-eco.png`}
								style={{
									display: "flex",
									justifyContent: "center",
									width: "45px",
									borderBottomLeftRadius: 10,
									borderBottomRightRadius: 10,
									borderTopRightRadius: 10,
									borderTopLeftRadius: 10,
								}}
							/>
							ฐานข้อมูล ECO Sticker
						</Menu.Item>
						<Menu.Item
							name="ฉบับร่าง"
							icon="save"
							active={active_tab === "draft"}
							onClick={() => {
								setActiveTab("draft");
								setPage(1);
							}}
						/>
					</Menu>

					{active_tab === "database" && (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-end",
								gap: 15,
								padding: "10px 0px",
							}}
						>
							<span>โครงสร้างยาง</span>
							<Dropdown
								value={type_structs}
								options={options_type_structs}
								placeholder="โครงสร้างยาง"
								search
								selection
								onChange={(e, data) => {
									setPage(1);
									setTypeStructs(data.value);
								}}
							/>
							{type_structs && (
								<div>
									<Icon
										name="close"
										link
										onClick={() => {
											setTypeStructs("");
										}}
										style={{ cursor: "pointer" }}
									/>
								</div>
							)}
							<span>เรียงโดย</span>
							<Dropdown
								value={sort_by}
								options={sort_mock_table}
								placeholder="น้อยมาก"
								search
								selection
								onChange={(e, data) => {
									setPage(1);
									setSortBy(data.value);
								}}
							/>
						</div>
					)}

					<MainTable
						data={active_tab === "database" ? data.tyre_requests_list : data.darft_tyre_requests_list}
						// columns={active_tab === "database" ? columns : draft_column}
						columns={columns}
						activeMenu={active_tab}
					/>
					<div style={{ alignSelf: "flex-end", flex: 1, padding: "10px 0px" }}>
						<OiePagination
							handleChangePage={fnHandleChangePage}
							totalPages={data?.info?.total_pages}
							page={parseInt(page)}
							handleChangeRowsPerPage={fnHandleChangeRowsPerPage}
							rowsPerPage={parseInt(rows_per_page)}
							totalRows={data?.info?.total_rows}
						/>
					</div>
				</Container>
			</div>
			<ModalEditData
				onOpen={() => setOpenModal(true)}
				open={open_modal}
				onClose={() => {
					setOpenModal(false);
					setSelectDataProductById();
					setKey();
				}}
				productId={selected_product_id}
				keyId={key}
			/>
		</div>
	);
};

export default RequestIndexNewest;
